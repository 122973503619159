.app-journal-nav {
	@include u-list-reset;
	border-bottom: 1px solid greyscale(80);
	margin-bottom: spacing(16);
}

.app-journal-nav__item {
	@include u-text-interface;
	display: inline-block;
	margin-bottom: spacing(16);

	&:not(:last-child) {
		margin-right: spacing(24);
	}
}

.app-journal-nav__link {
	@include unvisited-link;
	display: inline-flex;
	align-items: center;

	svg {
		fill: currentColor;
		width: initial; // temp fix to frontend-toolkits issue with svg[aria-hidden] basic.scss styles. to be removed when toolkits fixed.
		height: initial; // temp fix to frontend-toolkits issue with svg[aria-hidden] basic.scss styles. to be removed when toolkits fixed.
	}
}
