/**
 * These styles are for <table> and their permitted elements converted to <div> with class names that denote their
 * former semantic existence
 *
 * Initial placeholder styles taken from base tables.
 */

.jflow-table {
	display: table;
	width: 100%;
	margin-bottom: spacing(32);
}

.jflow-td,
.jflow-th {
	display: table-cell;
	vertical-align: top;
}

.jflow-th {
	text-align: left;
}

.jflow-td {
	vertical-align: top;

	p:last-child {
		margin-bottom: 0;
	}
}

.jflow-tr {
	display: table-row;
}
