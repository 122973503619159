
.app-conference-series-timeline {
	list-style: none;
	margin: spacing(24);
	padding: 0;

	@include media-query('sm') {
		margin-left: 80px;
	}
}

.app-conference-series-timeline__year {
	position: relative;
	border-left: 2px dotted greyscale(50);
	padding: spacing(4) spacing(8) spacing(8) spacing(24);
}

.app-conference-series-timeline__year::before {
	content: ' ';
	position: absolute;
	left: -13px;
	top: 8px;
	padding-left: 1.5em;
	height: 100%;
	background: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath d="M12 18a6 6 0 100-12 6 6 0 000 12zm0 2a8 8 0 110-16 8 8 0 010 16z" fill="%23FCFCFC" fill-rule="nonzero"/%3E%3Cpath d="M12 16a4 4 0 100-8 4 4 0 000 8zm0 2a6 6 0 110-12 6 6 0 010 12z" fill="%23B3B3B3" fill-rule="nonzero"/%3E%3Ccircle fill="%23FCFCFC" cx="12" cy="12" r="4"/%3E%3C/g%3E%3C/svg%3E') no-repeat;
}

.app-conference-series-timeline__marker {
	display: block;
	padding-top: spacing(2);

	@include media-query('sm') {
		position: absolute;
		left: -100px;
		top: 4px;
		width: 80px;
		text-align: right;
	}
}

.app-conference-series-timeline__heading {
	margin: 0 0 spacing(8);
}

.app-conference-series-timeline__inline-list {
	margin: 0 0 spacing(4);
	padding: 0;
}

.app-conference-series-timeline__inline-list > li {
	display: inline;
}

.app-conference-series-timeline__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.app-conference-series-timeline__item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border: 1px solid greyscale(80);
	margin: 0 0 spacing(8);
	padding: spacing(8) spacing(16);
}

.app-conference-series-timeline__item-title {
	margin: 0 0 spacing(8);
	flex-basis: 100%;
	flex-grow: 1;

	@include media-query('md') {
		margin: 0 auto 0 0;
		flex-basis: 0;
	}
}

.app-conference-series-timeline__item-count {
	flex-grow: 1;
	margin: 0;
	padding: 0;

	@include media-query('md') {
		flex-grow: 0;
		border-left: 1px solid greyscale(80);
		margin: 0 spacing(8);
		padding: spacing(8);
	}
}

.app-conference-series-timeline__item-count + .app-conference-series-timeline__item-count {
	border-left: 1px solid greyscale(80);
	padding-left: spacing(8);
}

.app-conference-series-timeline__item-count > span {
	display: block;
}

.app-conference-series-timeline__item-count-value {
	font-weight: $context--font-weight-bold;
}
