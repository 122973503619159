.app-footer {
	background-color: #fcfcfc;
	color: greyscale(25);
}

.app-footer-edition-list a,
.app-footer-edition-list a:active,
.app-footer-edition-list a:hover,
.app-footer-edition-list a:visited,
.app-footer__copyright a,
.app-footer__copyright a:active,
.app-footer__copyright a:hover,
.app-footer__copyright a:visited,
.app-footer__nav a,
.app-footer__nav a:active,
.app-footer__nav a:hover,
.app-footer__nav a:visited {
	color: greyscale(25);
}

.app-footer__container {
	@include u-container;
	font-family: $context--font-family-sans;
	font-size: .875rem;
	padding-top: spacing(16);
	padding-bottom: spacing(16);
}

.app-footer__aside-wrapper {
	border: 1px solid greyscale(80);
	border-left: 0;
	border-right: 0;
}

.app-footer__strapline {
	font-size: 1.0625rem;
	display: block;
	margin-top: 0;
	margin-bottom: 16px;

	@include media-query('md') {
		display: inline-block;
		margin-bottom: 0;
	}
}

.app-footer__edition {
	@include media-query('md') {
		float: right;
		margin-bottom: 0;
	}
}

.app-footer-edition-list {
	@include u-list-reset;

	li {
		display: inline-block;

		&:first-child {
			border-right: 1px solid greyscale(80);
			margin-right: .5em;
			padding-right: .5em;
		}

		&.selected a {
			font-weight: 700;
		}
	}
}

.app-footer__nav {
	@include u-list-reset;
	margin: 0 0 spacing(16);

	li {
		display: inline-block;
		border-right: 1px solid greyscale(80);;
		margin-right:  0.5em;
		padding-right: 0.5em;

		&:last-child {
			border-right: none;
			padding-right: 0;
		}
	}
}

.app-footer__parent-logo {
	display: inline-block;
	margin-bottom: spacing(16);
}

.app-footer__copyright,
.app-footer__user-access-info {
	font-size: .75rem;
	margin: 0;
}
