.app-sidebar-boxes {
	$column-spacing: spacing(24);

	@include media-query('sm' 'lg', 'range') {
		display: flex;
		flex-wrap: wrap;
		margin-left: 0 - $column-spacing;

		> * {
			flex: 1 0 250px;
			max-width: calc(50% - 24px); // Must be static value
			margin-left: $column-spacing;
		}
	}
}
