.app-submission-guidelines__section {
	font-family: $context--font-family-sans;
	border-bottom: 1px solid greyscale(80);
	margin-bottom: spacing(24);
	padding-bottom: spacing(24);

	li {
		word-wrap: break-word;
	}

	&:last-child {
		border: 0;
	}
}
