.app-collections__item-container {
	@include media-query('md') {
		display: flex;
		width: 100%;
	}
}

.app-collections-article__count {
	font-size: $context--font-size-xs;
	font-family: $context--font-family-sans;
	vertical-align: middle;
	margin-left: spacing(4);
}

.app-collections-article__list img {
	border: 1px solid greyscale(50, 0.2);
	max-width: 230px;
	max-height: 125px;
}

.app-collections-participating-journal__main {
	flex: 999 1 0%;
}

.app-collections--hidden-on-mobile {
	@include media-query('md', 'max') {
		@include visually-hidden();
	}
}

.app-collections-participating-journal__side {
	@include media-query('sm') {
		flex: 1 0 80px;
	}
	@include media-query('md') {
		flex: 1 0 175px;
	}
}

.app-collections__item {
	@include media-query('md') {
		display: flex;
	}
}

.app-collections-article {
	@include media-query('md') {
		display: flex;
	}
}

.app-collections__item--separated {
	@include media-query('md') {
		display: flex;
		border-right: 1px solid greyscale(80);
		margin-bottom: spacing(0);
	}
	margin-bottom: spacing(24);
}

.app-collections__item--separated:last-child {
	@include media-query('md') {
		border-right: none;
	}
}

.app-collections__item--separated {
	.c-list-description__item {
		margin-bottom: spacing(0);
	}
}

.app-collections-article__main {
	margin-bottom: spacing(16);
	padding-bottom: spacing(16);
	border-bottom: 1px solid greyscale(80);
	@include media-query('md') {
		margin-bottom: spacing(0);
		margin-right: spacing(16);
		padding-bottom: spacing(0);
		border-bottom: 0;
		padding-right: spacing(16);
		border-right: 1px solid greyscale(80);
		flex: 999 1 0%;
	}
}

.app-collections-article__side {
	@include media-query('md') {
		flex: 1 0 40%;
	}
}

.app-collections-guest-editors__item {
	display: flex;
	padding-bottom: spacing(24);
	margin-bottom: spacing(24);
	border-bottom: 1px solid greyscale(80);
}

.app-collections-guest-editors__item:last-child {
	border-bottom: none;
	padding-bottom: spacing(0);
}

.js .app-collections-guest-editors__item {
	display: none;
}

.js .app-collections-guest-editors--show-all .app-collections-guest-editors__item,
.js .app-collections-guest-editors--visible {
	display: flex;
}

.app-collections-guest-editors__name {
	font-weight: 700;
}

.app-collections-guest-editors__button {
	color: #004b83;
	text-decoration: underline;
	text-decoration-skip-ink: auto;
	cursor: pointer;
	margin: 0;
	background-color: transparent;
	border: 0;
	padding: 0;
	display: inline;
	width: auto;
}

.app-collections-guest-editors__image {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	flex-shrink: 0;
}

.app-collections-guest-editors__list {
	color: greyscale(40);
}

.app-collections-guest-editors__list--truncated {
	> li:not(:only-child):last-child::before {
		content: ', ';
	}

	> li:not(:only-child):last-child::after {
		content: '... ';
	}
}

.app-collections-filter__label {
	display: flex;
	align-items: center;
	margin-bottom: spacing(8);
}

.app-collections-filter__buttons {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		display: inline-block;
	}

	@include media-query('sm', 'max') {
		> li:not(:only-child):not(:last-child) {
			margin-bottom: spacing(8);
		}
	}
}

@each $status, $value in $collections-status-theme {
	.app-collections--#{$status} {
		color: $value;
	}
}
