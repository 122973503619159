$app-journal-metrics--padding: spacing(16);
$app-journal-metrics--margin: spacing(16);

.app-journal-metrics {
	margin-bottom: spacing(48);

	@include media-query('sm') {
		display: flex;
		position: relative;
		overflow: hidden;
		background-color: #fff;
		border-bottom-left-radius: 20px;
		padding: $app-journal-metrics--padding $app-journal-metrics--padding ($app-journal-metrics--padding * 2);
		box-shadow: 0 0 5px 0 greyscale(50, 0.1);
	}
}

.app-journal-metrics__bg {
	display: none;

	@include media-query('sm') {
		display: block;
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		margin-left: (0 - $app-journal-metrics--padding);
		margin-top: (0 - $app-journal-metrics--padding);

	}
}

.app-journal-metrics__column {
	@include media-query('sm') {
		flex: 1;
		z-index: 2;
		font-size: $context--font-size-sm;
		margin-left: $app-journal-metrics--margin;
		padding-left: $app-journal-metrics--padding;
	}
}

.app-journal-metrics__column:nth-of-type(1) {
	@include media-query('sm') {
		margin-left: 0;
		padding-left: 0;
		border-left: 0;
	}
}

.app-journal-metrics__column .app-journal-metrics__details:nth-child(1n + 3) {
	@include media-query('sm') {
		margin-top: $app-journal-metrics--margin;
	}
}

.app-journal-metrics__details {
	font-weight: 700;
	margin-top: $app-journal-metrics--margin;

	@include media-query('sm') {
		margin-top: 0;
	}
}
