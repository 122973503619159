.c-list-societies {
	@include u-list-reset;
	@include u-text-interface;
	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		height: 1px;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $context--page-background;
	}

	@include media-query('sm') {
		display: flex;
		flex-wrap: wrap;
	}
}

.c-list-societies__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: $c-list-societies-border;
	padding-top: $c-list-societies-padding;
	padding-right: 0;
	padding-bottom: $c-list-societies-padding;
	padding-left: 0;

	&:first-child {
		margin-top: 0;
		border-top: 0;
	}

	@include media-query('sm') {
		flex: 1 1 50%;
		max-width: 50%;

		&:nth-child(odd) {
			border-right: $c-list-societies-border;
			padding-right: $c-list-societies-padding;
		}

		&:nth-child(even) {
			padding-left: $c-list-societies-padding;
			padding-right: 0;
		}

		&:only-child {
			border-right: 0;
			flex: 0 1 auto;
			justify-content: normal;
		}
	}
}

.c-list-societies__text {
	margin-right: spacing(16);
	display: inline-block;
	flex: 1 1 auto;
	max-width: 100%;

	@include media-query('sm') {
		.c-list-societies__item:only-child & {
			flex: 0 1 auto; // to ensure that the logo is next to the text in cases where there is only one society and it spans the entire main col width
		}
	}

	a {
		@include u-link-interface;
	}
}

.c-list-societies__image {
	flex: 0 0 auto;

	& img {
		width: 80px;
	}
}
