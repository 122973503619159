.app-latest-issue__cover {
	margin-right: spacing(16);
	position: relative;
	z-index: 5;

	img {
		box-shadow: 0 0 5px 2px greyscale(50, 0.2);
		border: 2px solid white;
		border-radius: 4px;
		max-height: $cover-height * 1.4; // needs to be 40% bigger
	}
}
