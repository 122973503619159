// Temp design tokens below to enable use of eds-footer on link.springer.com pages that do not use EDS
// To be removed once all link.springer.com pages use the new Darwin design

// springernature tokens
$t-l-line-height-tight: 1.4;
$t-l-font-size-400: 1rem;
$t-l-color-white: #fff;
$t-l-font-family-sans: 'Merriweather Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$t-l-color-blue-900: #01324b;
$t-l-spacing-absolute-600: 24px;
$t-l-spacing-absolute-0: 0;

// eds-c-footer.tokens.scss
$t-a-typography-line-height-tight: $t-l-line-height-tight;
$t-a-typography-font-size-s: $t-l-font-size-400;
$t-a-palette-default-white: $t-l-color-white;
$t-a-typography-font-family-default: $t-l-font-family-sans;
$t-a-palette-default-blue-dark-900: $t-l-color-blue-900;
$t-a-typography-font-size-s: $t-a-typography-font-size-s;
$t-a-palette-default-white: $t-a-palette-default-white;
$t-a-typography-font-family-default: $t-a-typography-font-family-default;

// eds-c-footer.scss
$t-a-space-absolute-600: $t-l-spacing-absolute-600;
$t-a-space-absolute-0: $t-l-spacing-absolute-0;

@import '../../../../../node_modules/@springernature/elements/components/eds-c-footer';
