.app-collection-metadata {
	@include u-keyline($direction: top, $thickness: 2px);
	@include u-keyline($direction: bottom, $thickness: 2px);

	display: flex;
	flex-direction: column;

	@include media-query('lg') {
		flex-direction: row;
	}
}

.app-collection-metadata__item {
	margin-bottom: spacing(16);

	.app-collection-metadata > &:last-child {
		margin-bottom: 0;
	}

	@include media-query('lg') {
		margin-right: spacing(24);
		margin-bottom: 0;

		.app-collection-metadata > &:last-child {
			margin-right: 0;
		}
	}
}
