.c-two-col-layout {
	@include u-word-wrap;
	display: flex;
	flex-direction: column;
	height: auto;
}

.c-two-col-layout--row-at-lg {
	@include media-query('lg') {
		flex-direction: row;
	}
}

.c-two-col-layout__main {
	@include media-query('lg') {
		flex: 999 1 0%; // keep unit after 0 - https://github.com/philipwalton/flexbugs#flexbug-4
		margin-right: spacing(64);
	}
}

.c-two-col-layout__side {
	@include media-query('lg') {
		flex: 1 0 454px;
	}
}
