// MOVE OUT OF NESTED FOLDERS

.app-card-body {
	flex: 1 1 60%;
}

.app-card-image {
	aspect-ratio: auto;
	flex: 0 0 40%;
	max-width: 230px;

	img {
		height: auto;
		min-height: 0;
		position: relative;
		transform: translateY(0);
	}
}

.app-card-image-cover {
	aspect-ratio: auto;
	flex: 0 0 96px;
	max-width: 96px;

	img {
		box-shadow: 0 0 5px 2px greyscale(50, 0.2);
		border: 2px solid #fff;
		border-radius: 4px;
		position: relative;
		height: auto;
		min-height: auto;
	}
}
