// Temp design tokens below to enable use of Global Header on link.springer.com pages that do not use EDS
// To be removed once all link.springer.com pages use the new Darwin design
$t-a-margin-absolute-100: 4px;
$t-a-margin-absolute-200: 8px;
$t-a-margin-absolute-400: 16px;
$t-a-margin-absolute-800: 32px;
$t-a-padding-absolute-100: 4px;
$t-a-padding-absolute-200: 8px;
$t-a-padding-absolute-400: 16px;
$t-a-padding-absolute-1200: 48px;
$t-a-palette-default-white: #fff;
$t-a-palette-default-blue-light: #cedbe0;
$t-a-palette-default-blue-dark-900: #01324b;
$t-a-palette-default-blue-lighter: #f0f7fc;
$t-a-link-color-resting: #025e8d;
$t-a-typography-font-size-s: 1rem;
$t-a-typography-line-height-md: 1.5;
$t-a-icon-default-height: 1.5rem;
$t-a-space-absolute-100: 4px;
$t-a-space-absolute-200: 8px;
$t-a-space-absolute-400: 16px;
$t-a-space-absolute-800: 32px;
$t-a-space-absolute-1200: 48px;

@import '../../../../../node_modules/@springernature/elements/components/eds-c-header';
