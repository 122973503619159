.app-promo-text {
	font-family: $context--font-family-sans;

	> .jflow-table:last-of-type {
		// specific for springer journals
		margin-bottom: 0;
	}

	b {
		font-weight: 500;
	}
}

.app-promo-text--keyline {
	border-bottom: 1px solid greyscale(80);
	margin-bottom: spacing(16);
	padding-bottom: spacing(16);
}

// Elements added with JS

.app-promo-text__hidden-container {
	margin-bottom: spacing(16);

	> *:last-child {
		margin-bottom: 0;
	}
}

.app-promo-text__hidden-container--padding {
	padding-top: 1.5em;
	margin-block-start: 0;
}

.app-promo-text__inline {
	display: inline;
}

.app-promo-text__emdash {
	font-weight: $context--font-weight-normal;
}

.app-promo-text__button {
	@include link-like;
	@include u-button-reset;
	font-weight: $context--font-weight-normal;
	display: inline;
	width: auto;
}
