/**
 * This uses settings from the box component.
 * Version changes to box may causes this to error.
 */

$side-content-width: 456px;
$theme-height: 50px;
$cover-height: 125px;
$journal-header-title-font-size: 1.875rem;

.app-journal-header__theme {
	height: $theme-height;
	margin: (-$box--padding) (-$box--padding) 0;
	position: relative;
	border-top-left-radius: $box--border-radius;
	border-top-right-radius: $box--border-radius;
	overflow: hidden;

	@include media-query('lg') {
		&::after {
			content: '';
			background-color: rgba(255, 255, 255, 0.15);
			width: $side-content-width;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}
}

.app-journal-header__content {
	padding-top: $box--padding;

	@include media-query('lg') {
		display: flex;
	}
}

.app-journal-header__main {
	display: flex;
	flex: 1 1 auto;
}

.app-journal-header__side {
	@include media-query('lg') {
		flex: 1 0 $side-content-width - $box--padding;
		max-width: $side-content-width - $box--padding;
		padding-left: $box--padding;
		margin-left: $box--padding;
	}
}

// when imprint logo exists
.app-journal-header__side:not(:empty) {
	border-top: $box--border;
	margin-top: $box--padding;
	padding-top: $box--padding;

	@include media-query('lg') {
		border-top: 0;
		border-left: $box--border;
		margin-top: 0;
		padding-top: 0;
	}
}

.app-journal-header__cover {
	margin-top: -$theme-height;
	margin-right: spacing(16);
	position: relative;
	z-index: 5;

	img {
		box-shadow: 0 0 5px 2px greyscale(50, 0.2);
		border: 2px solid white;
		border-radius: 4px;
		max-width: 96px;
		max-height: $cover-height;
	}
}

.app-journal-header__title {
	@include u-h1;
	line-height: 1.2;
	font-size: $journal-header-title-font-size;
	margin-bottom: 0;

	a {
		@include u-link-inherit;
	}
}

.app-journal-header__subtitle {
	font-size: $context--font-size-md;
	font-family: $context--font-family-sans;
	margin-bottom: 0;
}

.app-journal-header__imprint {
	max-height: $cover-height - $theme-height;
}
