.app-checklist-banner {
	display: flex;
	flex: 1 1 auto;
	border: $checklist-banner--border;
	font-size: $checklist-banner--font-size;
	background-color: $checklist-banner--background-color;
}

.app-checklist-banner__title {
	font-size: $checklist-banner--title-font-size;
	font-weight: 700;
	font-family: $context--font-family-sans;
	margin-bottom: spacing(8);
}

.app-checklist-banner__icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 0 $checklist-banner--icon-container-size;
	width: $checklist-banner--icon-container-size;
	background-color: $checklist-banner--icon-background;
}

.app-checklist-banner__copy {
	margin-bottom: spacing(8);
}

.app-checklist-banner__link {
	display: inline-flex;
	align-items: center;
	color: $checklist-banner--link-color;

	&.visited,
	&:visited {
		color: $checklist-banner--link-color;
	}

	&.hover,
	&:hover {
		color: $checklist-banner--link-color;
	}
}

.app-checklist-banner__body {
	padding: spacing(16);
}

.app-checklist-banner__paper-icon {
	@include u-icon(48px, 48px);
	width: 48px !important; // temp fix to frontend-toolkits issue with svg[aria-hidden] basic.scss styles. to be removed when toolkits fixed.
	height: 48px !important; // temp fix to frontend-toolkits issue with svg[aria-hidden] basic.scss styles. to be removed when toolkits fixed.
}

.app-checklist-banner__arrow-icon {
	@include u-icon(16px, 11px);
	margin: spacing(4) 0 0 spacing(8);
}
