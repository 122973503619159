.app-section {
	margin-bottom: spacing(48);
}

.app-section__heading {
	@include u-container;
	@include u-h3;
	border-bottom: 2px solid greyscale(40);
	margin: 0 0 spacing(24);
	padding: 0 0 spacing(8);

	@include media-query('lg') {
		position: relative;
	}
}

.app-section__heading--space-between-at-sm {
	@include media-query('sm') {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		min-width: 0;
	}
}
