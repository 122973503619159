.app-volumes-and-issues__content {
	@include media-query('sm') {
		display: flex;
	}
}

.app-volumes-and-issues__article-list {
	border-top: 1px solid #ccc;
	margin-top: spacing(24);
}

.app-volumes-and-issues__title {
	font-size: $context--font-size-sm;
	font-family: $context--font-family-sans;
	margin-bottom: spacing(24);
}

.app-volumes-and-issues__copy {
	font-size: $context--font-size-sm;
	color: #666;
}

.app-volumes-and-issues__cover {
	display: none;
	position: relative;
	z-index: 5;
	margin-right: spacing(16);

	img {
		box-shadow: 0 0 5px 2px greyscale(50, 0.2);
		border: 2px solid white;
		border-radius: 4px;
		max-height: 182px;
	}

	@include media-query('sm') {
		display: block;
		max-width: 20%;
		margin-right: spacing(24);
		flex: 0 0 auto;
	}
}

.app-volumes-and-issues__info {
	@include media-query('sm') {
		flex: 1 1 auto;
	}
}
