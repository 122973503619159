.u-divider {
	border-bottom: 1px solid $context--keyline-border-color;
}

.c-author-list--book-series {
	color: greyscale(40);
}

.c-author-list--simple-comma-separated {
	> li:not(:only-child):last-child::before {
		content: ', ';
	}

	> li:not(:only-child):last-child::after {
		content: '...';
	}
}
