.app-open-access__section {
	font-family: $context--font-family-sans;
	margin-bottom: spacing(24);
	padding-bottom: spacing(24);

	li {
		word-wrap: break-word;
	}

	&:last-child {
		border: 0;
	}
}

.app-open-access-box__container {
	margin-bottom: spacing(16);
	@include media-query('md') {
		display: flex;
		justify-content: space-between;
	}
}

.app-open-access-box__icon {
	margin-bottom: spacing(16);
}

.app-open-access-box__title {
	font-size: 1.125rem;
	line-height: 1.4;
	font-family: $context--font-family-sans;
	font-weight: 700;
	margin-bottom: spacing(4);
}

.app-open-access-box__item {
	margin-bottom: spacing(16);
	background-color: greyscale(90);
	padding: spacing(16);

	&:last-child {
		margin-bottom: spacing(0);
	}

	@include media-query('md') {
		margin-bottom: spacing(0);
		flex: 0 0 32%;
		max-width: 32%;
	}
}
