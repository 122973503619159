@charset "UTF-8";
/**
 * Enhanced
 * Bootstrap the rest of the styles on cut the mustard
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Style mixins
 *
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
.c-card--major .c-card__title {
  font-size: 1.5rem;
  font-weight: 700;
}

.c-card__title {
  font-size: 1.125rem;
  font-weight: 700;
}

/**
 * Colour palette
 *
 */
/**
 * Color and gray scales
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 *  Button settings
 *  Springer
 *
 */
/**
 * Generates utility classnames for base and each breakpoint
 *
 * Example:
 * @include class-stack('u-text-right') would output
 * u-text-right { @content }
 * @include media-query('xs') { u-text-right-at-lt-xs { @content } }
 *
 */
/**
 * Default link style
 *
 */
/**
 * Alternative link color
 *
 */
/**
 * Deemphasised link color
 *
 */
/**
 * Unvisited
 *
 */
/**
 * Link like
 * Style non-links to look like links
 * Remember to add aria
 *
 */
button {
  line-height: inherit;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 4px solid #ffcc00;
}

input[type=file]:focus-within {
  outline: 4px solid #ffcc00;
}

button:disabled:focus,
input:disabled:focus,
select:disabled:focus,
textarea:disabled:focus {
  outline: none;
}

label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

/*
 * Layout
 * Universal layout styles
 */
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  height: 100%;
  overflow-y: scroll;
  font-size: 100%;
  box-sizing: border-box;
  color: #333333;
  line-height: 1.6180339888;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  max-width: 100%;
  min-height: 100%;
  background: #fcfcfc;
  font-size: 1.125rem; /* fixes chrome rems bug - http://stackoverflow.com/questions/20099844/chrome-not-respecting-rem-font-size-on-body-tag */
}

figure {
  margin: 0;
}

body,
div,
dl,
dt,
dd,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
th,
td {
  margin: 0;
  padding: 0;
}

abbr[title] {
  text-decoration: none;
}

[contenteditable]:focus,
[tabindex="0"]:focus {
  outline: 4px solid #ffcc00;
}

a {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
a.visited, a:visited {
  color: #a345c9;
}
a.hover, a:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
a.active, a:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
a.focus, a:focus {
  outline: 4px solid #ffcc00;
}
a > img {
  vertical-align: middle;
}

table {
  border: 1px solid rgb(165.75, 165.75, 165.75);
  width: 100%;
  margin-bottom: 32px;
}

th,
td {
  font-size: 1rem;
  border: 1px solid rgb(165.75, 165.75, 165.75);
  padding: 0.3em 0.6em;
  vertical-align: top;
}

th {
  background: rgb(229.5, 229.5, 229.5);
  text-align: left;
}

h1 {
  font-style: normal;
  line-height: 1.4;
  font-size: 2.25rem;
  font-size: min(max(1.75rem, 4vw), 2.25rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
h1 a.active, h1 a:active, h1 a.hover, h1 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

h2 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
h2 a.active, h2 a:active, h2 a.hover, h2 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

h3 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
h3 a.active, h3 a:active, h3 a.hover, h3 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

h4 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 2.5vw), 1.25rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
h4 a.active, h4 a:active, h4 a.hover, h4 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

h5,
h6 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.125rem;
  font-size: min(max(1rem, 2.5vw), 1.125rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
h5 a.active, h5 a:active, h5 a.hover, h5 a:hover,
h6 a.active,
h6 a:active,
h6 a.hover,
h6 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

/* Basic lists should be aligned to the left */
ul:not([class]),
ol:not([class]) {
  /* Allow for bullet itself */
  padding-left: 0.9em;
}

dd {
  margin: 0;
}

cite {
  font-style: normal;
}

/**
 * 10-Settings
 */
/**
 * @springernature/global-ad
 * Default skin settings
 *
 */
/**
 * @springernature/global-author-list
 * Springer skin settings
 *
 */
/**
 * @springernature/global-author-list
 * Default skin settings
 *
 */
/**
 * @springernature/global-banner
 * Springer skin settings
 */
/**
 * @springernature/global-banner
 * Default skin settings
 *
 */
/**
 * Card
 * Default branding for component
 */
/**
 * @springernature/global-corporate-footer
 * Springer skin settings
 */
/**
 * @springernature/global-corporate-footer
 * Default skin settings
 *
 */
/**
 * List
 * Springer branding for component
 */
/**
 * Meta
 * Springer branding for component
 */
/**
 * Meta
 * Default branding for component
 */
/**
 * Popup
 * Default branding for component
 */
/**
 * @springernature/global-skip-link
 * Springer skin settings
 *
 */
/**
 * @springernature/global-skip-link
 * Default skin settings
 *
 */
/**
 * Status Message
 * Default branding for component
 */
/**
 * @springernature/global-layout-with-sidebar
 * Springer skin settings
 */
/**
 * @springernature/global-layout-with-sidebar
 * Default skin settings
 *
 */
.app-author-services-banner {
  z-index: 100001;
  color: #222;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Daytona W01 Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner {
    width: 670px;
    left: calc(50% - 335px);
    right: unset;
  }
}
.app-author-services-banner--is-visible {
  animation-name: slide-from-bottom;
  animation-duration: 0.6s;
  transform: translateY(0);
}

.app-author-services-banner__banner-large, .app-author-services-banner__banner-small {
  display: block;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.1);
}

.app-author-services-banner__banner-large {
  position: relative;
  display: none;
  max-width: 670px;
  border: 1px solid #b0b0b0;
  border-bottom: none;
}

.app-author-services-banner__banner-small {
  border-top: 1px solid #b0b0b0;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__banner-large {
    display: block;
  }
  .app-author-services-banner__banner-small {
    display: none;
  }
}
.app-author-services-banner__large-container, .app-author-services-banner__small-container, .app-author-services-banner__large-content-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.app-author-services-banner__large-container, .app-author-services-banner__small-container {
  width: 100%;
}

.app-author-services-banner__small-container {
  justify-content: space-between;
}

.app-author-services-banner__large-content-container {
  padding: 16px 16px 16px 0;
  flex-grow: 1;
}

.app-author-services-banner__small-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: nowrap;
  padding: 12px;
}

.app-author-services-banner__large-text-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-left: 24px;
  margin-right: 16px;
  flex-direction: column;
  flex-wrap: nowrap;
}

.app-author-services-banner__small-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  margin-top: 12px;
}

.app-author-services-banner__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.273;
  max-width: 150px;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__text {
    font-size: 22px;
    max-width: 270px;
  }
}
.app-author-services-banner__small-image {
  display: block;
  max-width: 90px;
}

.app-author-services-banner__large-image {
  display: block;
}

.app-author-services-banner__image-cover-text {
  position: absolute;
  top: 137px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.273;
  padding: 4px 0;
  letter-spacing: -0.12px;
  text-align: center;
}

.app-author-services-banner__button {
  padding: 6px 8px;
  background-color: #EF2929;
  color: #fff;
  display: inline-block;
  max-width: 90px;
  text-align: center;
  line-height: 1.273;
  font-size: 11px;
}

a.app-author-services-banner__button:visited {
  color: #fff;
}

.app-author-services-banner__button span {
  font-weight: 400;
  letter-spacing: -0.13px;
  display: inline-block;
  transform: translateY(1px);
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__button {
    max-width: 124px;
    font-size: 16px;
  }
}
.app-author-services-banner__logo {
  max-width: 83px;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__logo {
    max-width: 162px;
  }
}
.app-author-services-banner__close-container {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 9px;
  top: 11px;
  border: 0;
  display: inline-block;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__close-container {
    right: 8px;
    top: 10px;
  }
}
.app-author-services-banner__close {
  margin-top: 0;
  background: 0 0;
  color: #222;
  vertical-align: top;
  border: 0;
  padding-right: 0;
}

@media only screen and (min-width: 641px) {
  .app-author-services-banner__close {
    height: inherit;
  }
}
@keyframes slide-from-bottom {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 200px;
  }
}
video {
  display: block;
}

.c-video {
  border: 1px solid #cccccc;
  background-color: rgb(242.25, 242.25, 242.25);
}

.c-video__wrapper {
  position: relative;
}
.c-video__wrapper::before {
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 56.28272%;
}
.c-video__wrapper::after {
  content: "";
  display: table;
  clear: both;
}
.c-video--miVMPro .c-video__wrapper::before {
  padding-top: 60.4%;
}

.c-video__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.c-video__content iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.c-video__warning {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  padding: 48px;
  margin: 0;
  text-align: center;
}

.c-video__warning--hide {
  display: none;
}

.c-video__caption {
  padding: 16px;
  font-size: 0.875rem;
  background-color: white;
  border-top: 1px solid #cccccc;
}
.c-video__caption *:last-child {
  margin-bottom: 0;
}

/**
 * 50-Components
 */
.c-ad {
  text-align: center;
}
@media only screen and (min-width: 320px) {
  .c-ad {
    padding: 8px;
  }
}

.c-ad--728x90,
.c-ad--970x90 {
  display: none;
  background-color: #ccc;
}
.c-ad--728x90 .c-ad__inner,
.c-ad--970x90 .c-ad__inner {
  min-height: calc(1.5em + 4px + 90px);
}
.c-ad--728x90 iframe,
.c-ad--970x90 iframe {
  max-width: 970px;
  height: 90px;
}

@media only screen and (min-width: 768px) {
  .js .c-ad--728x90 {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .js .u-show-following-ad + .c-ad--728x90 {
    display: block;
  }
}

.c-ad--160x600 {
  display: none;
  padding: 0;
  text-align: left;
}
.c-ad--160x600 iframe {
  width: 160px;
  height: 600px;
}

@media only screen and (min-width: 768px) {
  .js .c-ad--160x600 {
    display: block;
  }
}

.c-ad--300x250 {
  display: none;
  background-color: #f2f2f2;
  padding: 8px;
}
.c-ad--300x250 iframe {
  width: 300px;
  height: 250px;
}
.c-ad--300x250 .c-ad__inner {
  min-height: calc(1.5em + 4px + 250px);
}

@media only screen and (min-width: 320px) {
  .js .c-ad--300x250 {
    display: block;
  }
}

.c-ad iframe {
  border: none;
  overflow: auto;
  vertical-align: top;
}

.c-ad__label {
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  margin-bottom: 4px;
  color: #333;
  line-height: 1.5;
}

.c-author-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: inherit;
  color: #333;
  line-height: inherit;
}
.c-author-list > li {
  display: inline;
}
.c-author-list > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.c-author-list > li:not(:only-child):last-child::before {
  content: " & ";
}

.c-author-list--compact {
  line-height: 1.4;
  font-size: 0.875rem;
}

.c-author-list--truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.js .c-author-list {
  display: inline;
}
.js .c-author-list__hide {
  display: none;
  visibility: hidden;
}
.js .c-author-list__hide:first-child + * {
  margin-block-start: 0;
}

/**
 * @springernature/global-banner
 * A container with text and link
 *
 */
.c-banner {
  border-bottom: 1px solid rgb(0, 58.6666666667, 132);
  background-color: #f7fbfe;
  padding: 16px 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.c-banner--compact {
  font-size: 0.875em;
  padding: 4px 4px;
}

.c-banner--contrast {
  background-color: black;
  color: white;
}
.c-banner--contrast .c-banner__link {
  color: white;
}
.c-banner--contrast .c-banner__link:hover, .c-banner--contrast .c-banner__link:visited {
  color: white;
}

.c-banner--marketing {
  background-color: #004b83;
  border-bottom: 0;
  color: white;
}
.c-banner--marketing .c-banner__link {
  color: white;
}
.c-banner--marketing .c-banner__link:hover, .c-banner--marketing .c-banner__link:visited {
  color: white;
}

.c-banner--flush {
  padding-left: 0;
  padding-right: 0;
}

.c-banner__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-banner__container > :only-child {
  margin-left: auto;
  margin-right: auto;
}

.c-banner__container--center {
  justify-content: center;
}

.c-banner__item {
  flex: 0 1 auto;
  margin: 0;
}

.c-banner__link {
  text-decoration: underline;
  color: #004b83;
}
.c-banner__link:visited, .c-banner__link:hover {
  color: #004b83;
}

.c-breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: black;
}
.c-breadcrumbs > li {
  display: inline;
}

.c-breadcrumbs__link {
  color: #666666;
}
.c-breadcrumbs__link.visited, .c-breadcrumbs__link:visited, .c-breadcrumbs__link.hover, .c-breadcrumbs__link:hover {
  color: #666666;
}

svg.c-breadcrumbs__chevron {
  margin: 4px 4px 0;
  fill: #666666;
  width: 10px;
  height: 10px;
}

@media only screen and (max-width: 539px) {
  .c-breadcrumbs .c-breadcrumbs__item {
    display: none;
  }
}

@media only screen and (max-width: 539px) {
  .c-breadcrumbs .c-breadcrumbs__item:nth-last-child(1),
  .c-breadcrumbs .c-breadcrumbs__item:nth-last-child(2) {
    display: inline;
  }
}

/**
 * Card
 * Base component styles
 */
.c-card {
  display: flex;
  background-color: transparent;
  flex-direction: column;
  font-size: 14px;
  min-width: 0;
  padding: 0;
  position: relative;
  border: none;
  box-shadow: none;
  overflow: hidden;
}

.c-card--no-shape {
  border: none;
  box-shadow: none;
  background: none;
}

.c-card__image {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 56.25%;
}
@supports (aspect-ratio: 1/1) {
  .c-card__image {
    padding-bottom: 0;
    aspect-ratio: var(--card--image-aspect-ratio, 16/9);
  }
}
.c-card__image img {
  position: absolute;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}
@supports (object-fit: cover) {
  .c-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-card__body {
  padding: 16px;
  flex: 1 1 auto;
}

.c-card--no-shape .c-card__body {
  padding: 0;
}

.c-card--no-shape .c-card__body:not(:first-child) {
  padding-top: 16px;
}

.c-card__title {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 8px;
  letter-spacing: normal;
}

[lang=de] .c-card__title {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.c-card__summary {
  line-height: 1.4;
}
.c-card__summary > p {
  margin-bottom: 5px;
}
.c-card__summary > p:last-child {
  margin-bottom: 0;
}
.c-card__summary a {
  text-decoration: underline;
}

.c-card__link:not(.c-card__link--no-block-link)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.c-card--flush .c-card__body {
  padding: 0;
}

.c-card--major {
  font-size: 1rem;
}
.c-card--dark {
  background-color: #000;
  color: #fff;
  border-width: 0;
}
.c-card--dark .c-card__title {
  color: white;
}
.c-card--dark .c-card__link {
  color: inherit;
}
.c-card--dark .c-card__summary a {
  color: inherit;
}

.c-corporate-footer {
  background-color: rgb(242.25, 242.25, 242.25);
  border-top: 1px solid #cccccc;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding-top: 16px;
  padding-bottom: 24px;
}

.c-corporate-footer__legal {
  color: #666666;
  font-size: 1rem;
  margin-bottom: 0px;
  padding-top: 4px;
}

.c-corporate-footer__link {
  color: inherit;
}
.c-corporate-footer__link.visited, .c-corporate-footer__link:visited {
  color: inherit;
}
.c-corporate-footer__link.hover, .c-corporate-footer__link:hover {
  color: inherit;
}

.c-list-columned {
  column-gap: 24px;
}
@media only screen and (min-width: 540px) {
  .c-list-columned {
    column-count: 2;
  }
}
@media only screen and (min-width: 768px) {
  .c-list-columned {
    column-count: 3;
  }
}

.c-list-columned__item {
  margin-bottom: 0.25em;
}

.c-list-description {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  margin-bottom: 0;
  width: 100%;
}

.c-list-description__item {
  margin-bottom: 24px;
}
.c-list-description__item:last-child {
  margin-bottom: 0;
}

.c-list-description__term {
  font-weight: 700;
  margin-bottom: 0;
}

.c-list-description__details {
  display: block;
}

.c-list-description__details p {
  display: block;
  margin-bottom: 0;
}
.c-list-description__details p:last-child {
  margin-bottom: 0;
}

.c-list-group {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

.c-list-group__item > a.active, .c-list-group__item > a:active, .c-list-group__item > a.hover, .c-list-group__item > a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-list-group--xs .c-list-group__item {
  padding: 4px 0;
}

.c-list-group--sm .c-list-group__item {
  padding: 8px 0;
}

.c-list-group--md .c-list-group__item {
  padding: 16px 0;
}

.c-list-group--lg .c-list-group__item {
  padding: 24px 0;
}

.c-list-group--bordered .c-list-group__item:not(:last-child) {
  border-bottom: 1px solid #cccccc;
}

.c-list-group--flush .c-list-group__item:first-child {
  padding-top: 0;
}

.c-list-group--flush .c-list-group__item:last-child {
  padding-bottom: 0;
}

.c-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  color: #666666;
  line-height: inherit;
}

.c-meta--large {
  font-size: 1rem;
}
.c-meta--large .c-meta__item {
  margin-bottom: 8px;
}

.c-meta__item {
  display: inline-block;
  margin-bottom: 4px;
}
.c-meta__item:not(:last-child) {
  border-right: 1px solid rgb(216.75, 216.75, 216.75);
  padding-right: 8px;
  margin-right: 8px;
}

@media only screen and (max-width: 539px) {
  .c-meta__item--block-sm-max {
    display: block;
  }
  .c-meta__item--block-sm-max:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .c-meta__item--block-at-lg {
    display: block;
  }
  .c-meta__item--block-at-lg:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

.c-meta__type {
  text-transform: uppercase;
  font-weight: 700;
}

.c-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.875rem;
  padding: 16px;
}
@media only screen and (min-width: 540px) {
  .c-pagination {
    justify-content: center;
  }
}

.c-pagination__item {
  margin-right: 16px;
  margin-bottom: 8px;
}
.c-pagination__item:last-child {
  margin-right: 0;
}

.c-pagination__link {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #004b83;
  background-color: rgb(242.25, 242.25, 242.25);
  background-image: linear-gradient(to bottom, white, rgb(242.25, 242.25, 242.25));
  border: 1px solid #cccccc;
  min-width: 30px;
  text-align: center;
}
.c-pagination__link svg {
  fill: currentColor;
}
.c-pagination__link:visited {
  color: #004b83;
}
.c-pagination__link:hover {
  border: 1px solid #666666;
  text-decoration: none;
}
.c-pagination__link:focus {
  border: 1px solid #666666;
  text-decoration: none;
}
.c-pagination__link:hover, .c-pagination__link:focus {
  color: white;
  background-color: #666666;
  background-image: none;
}
.c-pagination__link:hover svg path, .c-pagination__link:focus svg path {
  fill: white;
}

.c-pagination__link--disabled {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #004b83;
  background-color: rgb(242.25, 242.25, 242.25);
  background-image: linear-gradient(to bottom, white, rgb(242.25, 242.25, 242.25));
  border: 1px solid #cccccc;
  color: #333333;
  background-color: transparent;
  background-image: none;
  border: 1px solid #cccccc;
  opacity: 0.67;
  cursor: default;
}
.c-pagination__link--disabled svg {
  fill: currentColor;
}
.c-pagination__link--disabled:visited {
  color: #004b83;
}
.c-pagination__link--disabled:hover {
  border: 1px solid #666666;
  text-decoration: none;
}
.c-pagination__link--disabled:focus {
  border: 1px solid #666666;
  text-decoration: none;
}
.c-pagination__link--disabled:hover, .c-pagination__link--disabled:focus {
  color: white;
  background-color: #666666;
  background-image: none;
}
.c-pagination__link--disabled:hover svg path, .c-pagination__link--disabled:focus svg path {
  fill: white;
}
.c-pagination__link--disabled svg {
  fill: currentColor;
}
.c-pagination__link--disabled:visited {
  color: #333333;
}
.c-pagination__link--disabled:hover {
  border: 1px solid #cccccc;
  text-decoration: none;
}
.c-pagination__link--disabled:focus {
  border: 1px solid #cccccc;
  text-decoration: none;
}
.c-pagination__link--disabled:hover, .c-pagination__link--disabled:focus {
  color: #333333;
  background-color: transparent;
  background-image: none;
}
.c-pagination__link--disabled:hover svg path, .c-pagination__link--disabled:focus svg path {
  fill: #333333;
}

.c-pagination__link--active {
  color: white;
  border-color: #666666;
  background-color: #666666;
  background-image: none;
  cursor: default;
}

.c-pagination__ellipsis {
  background: none;
  border: 0;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}

.c-pagination__icon {
  fill: #999999;
  width: 16px;
  height: 12px;
}

.c-pagination__icon--active {
  fill: #004b83;
}

.js .c-popup {
  position: absolute;
  font-family: Georgia, Palatino, serif;
  z-index: 100;
  padding: 16px;
  border: 1px solid #222222;
  box-shadow: 0 0 5px 0 rgba(127.5, 127.5, 127.5, 0.1);
  width: auto;
  border-radius: 2px;
  background-color: #fff;
}
.js .c-popup__close {
  position: absolute;
  display: block;
  top: 16px;
  right: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%0A%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.29679575 12.2772478c-.39658757.3965876-.39438847 1.0328109-.00062148 1.4265779.39651227.3965123 1.03246768.3934888 1.42657791-.0006214l4.27724782-4.27724787 4.2772478 4.27724787c.3965876.3965875 1.0328109.3943884 1.4265779.0006214.3965123-.3965122.3934888-1.0324677-.0006214-1.4265779l-4.27724787-4.2772478 4.27724787-4.27724782c.3965875-.39658757.3943884-1.03281091.0006214-1.42657791-.3965122-.39651226-1.0324677-.39348875-1.4265779.00062148l-4.2772478 4.27724782-4.27724782-4.27724782c-.39658757-.39658757-1.03281091-.39438847-1.42657791-.00062148-.39651226.39651227-.39348875 1.03246768.00062148 1.42657791l4.27724782 4.27724782z' fill='%23666' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat;
  border: none;
  padding-right: 16px;
}
.js .c-popup__close-text {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.js .c-popup__arrow {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-top: 1px solid #222222;
  border-left: 1px solid #222222;
}
.js .c-popup__arrow--above {
  bottom: -11px;
  transform: rotate(225deg);
}
.js .c-popup__arrow--below {
  top: -11px;
  transform: rotate(45deg);
}

/**
 * @springernature/global-skip-link
 * A full width link which is hidden for mouse users
 *
 */
.c-skip-link {
  background: #f7fbfe;
  color: #004b83;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-align: center;
  padding: 8px;
  position: absolute;
  inset: 0;
  bottom: auto;
  z-index: 9999;
  transform: translateY(-100%);
}
@media (prefers-reduced-motion: reduce) {
  .c-skip-link {
    transition: top 0.3s ease-in-out;
  }
}
@media print {
  .c-skip-link {
    display: none;
  }
}
.c-skip-link:link, .c-skip-link:active, .c-skip-link:visited, .c-skip-link:hover {
  color: #004b83;
}
.c-skip-link:focus {
  transform: translateY(0%);
}

.c-status-message {
  display: flex;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.c-status-message *:last-child {
  margin-bottom: 0px;
}

.c-status-message--boxed {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #cccccc;
  line-height: 1.4;
  padding: 16px;
}

.c-status-message__heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.c-status-message__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 auto;
  margin-right: 8px;
}

.c-status-message__icon--top {
  align-self: flex-start;
}

.c-status-message--info .c-status-message__icon {
  color: #003f8d;
}

.c-status-message--boxed.c-status-message--info {
  border-bottom: 4px solid #003f8d;
}

.c-status-message--error .c-status-message__icon {
  color: #c40606;
}

.c-status-message--boxed.c-status-message--error {
  border-bottom: 4px solid #c40606;
}

.c-status-message--success .c-status-message__icon {
  color: #00b8b0;
}

.c-status-message--boxed.c-status-message--success {
  border-bottom: 4px solid #00b8b0;
}

.c-status-message--warning .c-status-message__icon {
  color: #edbc53;
}

.c-status-message--boxed.c-status-message--warning {
  border-bottom: 4px solid #edbc53;
}

.l-with-sidebar {
  display: flex;
  flex-wrap: wrap;
}

.l-with-sidebar > * {
  margin: 0;
}

.l-with-sidebar__sidebar {
  flex-grow: 1;
  flex-basis: 454px; /* fallback static value for IE */
  flex-basis: var(--with-sidebar--basis, 454px);
}

.l-with-sidebar > :not(.l-with-sidebar__sidebar) {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 53%; /* fallback static value for IE */
  min-width: var(--with-sidebar--min, 53%);
}

.l-with-sidebar > :first-child {
  padding-right: 64px; /* gap for IE11 (so desktop) */
}

@supports (gap: 1em) {
  .l-with-sidebar > :first-child {
    padding-right: 0;
  }
  .l-with-sidebar {
    gap: var(--with-sidebar--gap, 64px);
  }
}
.c-dropdown {
  position: relative;
  display: inline-block;
}

.c-dropdown__button {
  background-color: transparent;
  border: 0;
  padding: 0;
  display: inline-block;
  white-space: nowrap;
}
.c-dropdown__button::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 5px;
  border-color: currentColor transparent transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.c-dropdown__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgb(216.75, 216.75, 216.75);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  position: absolute;
  top: 100%;
  transform: translateY(8px);
  width: 180px;
  z-index: 100;
}
.c-dropdown__menu::before, .c-dropdown__menu::after {
  position: absolute;
  bottom: 100%;
}
.c-dropdown__menu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent rgb(216.75, 216.75, 216.75) transparent;
  left: 16px;
  transform: translateX(-1px);
}
.c-dropdown__menu::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent white transparent;
  left: 16px;
}

.c-dropdown__menu--right {
  left: auto;
  right: 0;
}
.c-dropdown__menu--right::before, .c-dropdown__menu--right::after {
  left: auto;
  right: 16px;
}
.c-dropdown__menu--right::before {
  transform: translateX(1px);
}

.c-dropdown__link {
  display: block;
  padding: 4px 16px;
  background-color: transparent;
  color: #004b83;
}
.c-dropdown__link.active, .c-dropdown__link:active, .c-dropdown__link.hover, .c-dropdown__link:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.c-dropdown__link:visited {
  color: #004b83;
}
.c-dropdown__link:hover {
  background-color: rgb(0, 96.6984732824, 168.9);
  color: white;
}

.c-listing {
  list-style: none;
  padding: 0;
}

.c-listing__item {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.c-listing__content {
  min-width: 60%;
  flex-basis: 0;
  flex-grow: 666;
}

.c-listing__content > * + * {
  margin-top: 0.75rem;
}

.c-listing__item + .c-listing__item {
  margin-top: 16px;
  padding-top: 16px;
}

.c-listing__title {
  margin-bottom: 0;
}

.c-listing__authors {
  color: #666;
}

.c-listing__authors-list,
.c-listing__metadata {
  display: inline-flex;
  flex-wrap: wrap;
}

.c-listing__authors-list span + span:not(:first-child):not(:last-child)::after {
  content: ", ";
}

.c-listing__metadata span:not(:last-child)::after {
  content: "";
  border-left: 2px solid #cccccc;
  margin: 0 0.5em;
}

.c-listing__image {
  flex-basis: 200px;
  flex-grow: 1;
  position: relative;
  aspect-ratio: 9/6;
  align-self: flex-start;
}

.c-listing__image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #cccccc;
}

.c-listing__view-options {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
@supports (gap: 1em) {
  .c-listing__view-options {
    gap: 0.5em;
  }
}

@supports not (gap: 1em) {
  .c-listing__view-options li {
    margin: 0.25em;
  }
}

.c-listing__view-options a {
  display: flex;
  align-items: center;
}

.c-listing__view-options-icon {
  margin-right: 0.25em;
  fill: currentColor;
}

@supports (-webkit-line-clamp: 3) {
  .c-listing__intro--truncated {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: var(--listing-lines);
    -webkit-box-orient: vertical;
  }
}
.c-media {
  display: block;
}
@media only screen and (min-width: 540px) {
  .c-media {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 540px) {
  .c-media--stacked {
    display: block;
  }
}

.c-media__figure {
  position: relative;
}
@media only screen and (min-width: 540px) {
  .c-media__figure {
    max-width: 30%;
    flex: 1 0 30%;
  }
}
.c-media__figure a {
  display: block;
}

.c-media--stacked .c-media__figure {
  max-width: none;
}
@media only screen and (min-width: 540px) {
  .c-media--stacked .c-media__figure {
    margin-bottom: 16px;
  }
}

.c-media__body + .c-media__figure,
.c-media__figure + .c-media__body {
  margin-top: 16px;
}
@media only screen and (min-width: 540px) {
  .c-media__body + .c-media__figure,
  .c-media__figure + .c-media__body {
    margin-top: 0;
    margin-left: 16px;
  }
  .c-media--stacked .c-media__body + .c-media__figure,
  .c-media--stacked .c-media__figure + .c-media__body {
    margin-left: 0;
  }
}

.c-media__body,
.c-media__title {
  font-weight: normal;
}

.c-media__body {
  flex: 1 1 auto;
}
.c-media__body p {
  margin: 0;
}

.c-media__title {
  margin-bottom: 16px;
  font-size: 1.5rem;
}

.c-media__play {
  width: 40px;
  height: 40px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.c-media__play::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 14px;
  border-color: transparent transparent transparent #fff;
  margin-left: 3px;
}

.eds-c-header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  background-color: #fff;
  padding: 8px 0 0;
  border-bottom: 2px solid #01324b;
  font-size: 1rem;
  line-height: 1.5;
}

.eds-c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto 8px;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px 16px;
}

.eds-c-header__nav {
  border-top: 2px solid #cedbe0;
  padding-top: 4px;
  position: relative;
}

.eds-c-header__nav-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.eds-c-header__nav-container > *:not(:last-child) {
  margin-right: 32px;
}

.eds-c-header__link-container {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px 16px;
  justify-content: space-between;
}

.eds-c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eds-c-header__list-item {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-weight: bold;
  padding: 8px;
}
.eds-c-header__list-item:not(:last-child) {
  border-bottom: 2px solid #cedbe0;
}

.eds-c-header__item {
  color: inherit;
}

@media only screen and (min-width: 540px) {
  .eds-c-header__item--menu {
    display: none;
    visibility: hidden;
  }
  .eds-c-header__item--menu:first-child + * {
    margin-block-start: 0;
  }
}

.eds-c-header__item--inline-links {
  display: none;
  visibility: hidden;
}
.eds-c-header__item--inline-links:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 540px) {
  .eds-c-header__item--inline-links {
    display: flex;
    gap: 16px;
    visibility: visible;
  }
}

.eds-c-header__item--divider:before {
  content: "";
  position: absolute;
  border-left: 2px solid #cedbe0;
  height: calc(100% - 16px);
  top: 8px;
  margin-left: -15px;
}

.eds-c-header__brand {
  padding: 16px 8px;
}
.eds-c-header__brand a {
  display: block;
  line-height: 1;
  text-decoration: none;
}
.eds-c-header__brand img {
  height: 1.5rem;
  width: auto;
}

.eds-c-header__link {
  display: inline-block;
  padding: 16px 8px;
  color: inherit;
  font-weight: bold;
  text-decoration-color: transparent;
  position: relative;
  word-break: normal;
  white-space: nowrap;
}
.eds-c-header__link:visited {
  color: inherit;
}
.eds-c-header__link:hover {
  color: inherit;
  text-decoration-color: inherit;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover {
  text-decoration-color: transparent;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.eds-c-header__link--static {
  flex: 0 0 auto;
}

.eds-c-header__link.is-open {
  color: #025e8d;
}
.eds-c-header__link.is-open::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.eds-c-header__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  font-size: 1.5rem;
}
.eds-c-header__icon + * {
  margin-left: 8px;
}

.eds-c-header__expander {
  background-color: #f0f7fc;
}

.eds-c-header__search {
  display: block;
  padding: 24px 0;
}
@media only screen and (min-width: 540px) {
  .eds-c-header__search {
    max-width: 70%;
  }
}

.eds-c-header__search-container {
  position: relative;
}

.eds-c-header__search-label {
  display: inline-block;
  color: inherit;
  font-weight: bold;
  margin-bottom: 8px;
}

.eds-c-header__search-input {
  border: 1px solid black;
  width: 100%;
  padding: 8px 48px 8px 8px;
  background-color: white;
}

.eds-c-header__search-button {
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 0;
}

.has-tethered.eds-c-header__expander {
  margin-top: -2px;
  width: 100%;
  top: 100%;
  z-index: 10;
  left: 0;
  border-bottom: 2px solid #01324b;
}

@media only screen and (min-width: 540px) {
  .has-tethered.eds-c-header__expander--menu {
    display: none;
    visibility: hidden;
  }
  .has-tethered.eds-c-header__expander--menu:first-child + * {
    margin-block-start: 0;
  }
}

.has-tethered.eds-c-header__expander--account {
  transform: translateY(8px);
  width: auto;
  left: auto;
  position: absolute;
  right: 0;
  bottom: auto;
  margin-top: 0;
}

.has-tethered .eds-c-header__heading {
  display: none;
  visibility: hidden;
}
.has-tethered .eds-c-header__heading:first-child + * {
  margin-block-start: 0;
}
.has-tethered .eds-c-header__search {
  margin: auto;
}

.eds-c-header__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 16px 16px 0;
}

.eds-c-footer {
  font-size: 1rem;
  background-color: #01324b;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #fff;
  line-height: 1.4;
}

.eds-c-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
@media print {
  .eds-c-footer__container {
    display: none;
  }
}

.eds-c-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
}
.eds-c-footer__links li {
  display: inline-block;
}
.eds-c-footer__links li:not(:last-child) {
  margin-right: 24px;
}

.eds-c-footer__user {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 24px;
}

.eds-c-footer__user-info {
  margin-bottom: 16px;
  margin-top: 0;
  line-height: 1.4;
}

.eds-c-footer__legal {
  color: #fff;
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 0;
}

.eds-c-footer__link {
  line-height: 1.4;
  color: inherit;
}
.eds-c-footer__link.visited, .eds-c-footer__link:visited {
  color: inherit;
}
.eds-c-footer__link.hover, .eds-c-footer__link:hover {
  color: inherit;
}

.eds-c-footer__link > img {
  vertical-align: middle;
}

button.eds-c-footer__link {
  border: none;
  background: none;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: inherit;
  padding: 0;
  text-align: left;
}

.eds-c-footer__button-text {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.eds-c-footer__button-text.visited, .eds-c-footer__button-text:visited {
  color: #a345c9;
}
.eds-c-footer__button-text.hover, .eds-c-footer__button-text:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.eds-c-footer__button-text.active, .eds-c-footer__button-text:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
.eds-c-footer__button-text.focus, .eds-c-footer__button-text:focus {
  outline: 4px solid #ffcc00;
}
.eds-c-footer__button-text {
  color: inherit;
}
.eds-c-footer__button-text.visited, .eds-c-footer__button-text:visited {
  color: inherit;
}
.eds-c-footer__button-text.hover, .eds-c-footer__button-text:hover {
  color: inherit;
}

.eds-c-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eds-c-footer__grid {
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  .eds-c-footer__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 32px;
  }
}

.eds-c-footer__group {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .eds-c-footer__group {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@supports (display: grid) {
  .eds-c-footer__group {
    padding-right: 0;
    max-width: none;
    margin-bottom: 0;
  }
}

.eds-c-footer__group--separator {
  border-bottom: 2px solid #fff;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.eds-c-footer__heading {
  color: #fff;
  margin-bottom: 16px;
  font-weight: bold;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.eds-c-footer__heading--visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

.eds-c-footer__item:not(:last-child) {
  margin-bottom: 16px;
}

.app-frontend-toolkit {
  /* Fallback margins for IE11 */
}
.app-frontend-toolkit .c-header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
}
.app-frontend-toolkit .c-header {
  background-color: #fff;
  padding: 16px 0;
  border-bottom: 4px solid #00285a;
}
.app-frontend-toolkit .c-header__container > * + *,
.app-frontend-toolkit .c-header__menu > * + *,
.app-frontend-toolkit .c-header__nav-menu > * + * {
  margin-left: 2em;
}
@supports (gap: 2em) {
  .app-frontend-toolkit .c-header__container > * + *,
  .app-frontend-toolkit .c-header__menu > * + *,
  .app-frontend-toolkit .c-header__nav-menu > * + * {
    margin-left: 0;
  }
}
.app-frontend-toolkit .c-header__container,
.app-frontend-toolkit .c-header__menu,
.app-frontend-toolkit .c-header__nav-menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@supports (gap: 2em) {
  .app-frontend-toolkit .c-header__container,
  .app-frontend-toolkit .c-header__menu,
  .app-frontend-toolkit .c-header__nav-menu {
    gap: 2em;
  }
}
.app-frontend-toolkit .c-header__menu,
.app-frontend-toolkit .c-header__nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-frontend-toolkit .c-header__item {
  color: inherit;
}
.app-frontend-toolkit .c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  justify-content: space-between;
}
.app-frontend-toolkit .c-header__brand a {
  text-decoration: none;
  display: block;
}
.app-frontend-toolkit .c-header__brand img {
  /* based on springer.com logo's intrinsic height */
  height: 30px;
  width: auto;
}
.app-frontend-toolkit .c-header__link {
  color: inherit;
}
.app-frontend-toolkit .c-header__link.active, .app-frontend-toolkit .c-header__link:active, .app-frontend-toolkit .c-header__link.hover, .app-frontend-toolkit .c-header__link:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.app-frontend-toolkit .c-header__link:visited {
  color: inherit;
}
.app-frontend-toolkit .c-header__link:hover {
  color: inherit;
}
.app-frontend-toolkit .c-header__link--nav {
  font-weight: bold;
}
.app-frontend-toolkit .c-header__nav {
  border-top: 1px solid #cccccc;
  margin-top: 16px;
  padding-top: 16px;
}
.app-frontend-toolkit .c-header__nav-menu {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
.app-frontend-toolkit .c-header__search-form {
  padding: 48px;
  display: none;
  justify-content: center;
  background-color: rgb(229.5, 229.5, 229.5);
}
.app-frontend-toolkit .c-header__search-form:target,
.app-frontend-toolkit .c-header__search-form--visible {
  display: flex;
}
.app-frontend-toolkit .c-header__search-field {
  width: 40rem;
  max-width: 100%;
}
.app-frontend-toolkit .c-header__search-field label {
  display: block;
  margin-bottom: 4px;
}
.app-frontend-toolkit .c-header__search-controls {
  display: flex;
  margin-bottom: 8px;
}
.app-frontend-toolkit .c-header__search-controls input,
.app-frontend-toolkit .c-header__search-controls button {
  padding: 8px;
  border: 0;
  border-radius: 0;
}
.app-frontend-toolkit .c-header__search-controls input {
  width: 100%;
  border: 1px solid #cccccc;
  border-width: 1px 0 0 1px;
}
.app-frontend-toolkit .c-header__springer-link {
  font-size: 0.8em;
}

.c-box {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #cccccc;
  line-height: 1.3;
  padding: 16px;
}

.c-box--background-grey {
  background-color: rgb(242.25, 242.25, 242.25);
}

.c-box--shadowed {
  box-shadow: 0 0 5px 0 rgba(127.5, 127.5, 127.5, 0.1);
}

.c-box--borderless {
  border: none;
}

.c-box__heading {
  font-size: 1.5rem;
  border-bottom: 1px solid #cccccc;
  margin: 0 0 16px;
  padding: 0 0 8px;
}

.c-box__item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  display: block;
  margin-bottom: 16px;
}
.c-box__item.visited, .c-box__item:visited {
  color: #004b83;
}
.c-box__item.hover, .c-box__item:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.c-box__item.active, .c-box__item:active, .c-box__item.hover, .c-box__item:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-box__item-text {
  display: block;
  max-width: 90%;
}

.c-publisher-footer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  color: #fff;
  background-color: #01324b;
}

.c-publisher-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.c-publisher-footer__section {
  border-top: 2px solid #fff;
  padding-top: 24px;
  padding-bottom: 24px;
}

.c-publisher-footer__section--compact {
  padding-top: 16px;
  padding-bottom: 16px;
}

.c-publisher-footer__menu {
  column-gap: 24px;
}
@media only screen and (min-width: 320px) {
  .c-publisher-footer__menu {
    column-count: 2;
  }
}
@media only screen and (min-width: 768px) {
  .c-publisher-footer__menu {
    column-count: 4;
    column-gap: 32px;
  }
}

.c-publisher-footer__menu-group {
  break-inside: avoid-column;
  overflow: hidden;
  padding-left: 4px;
  margin-left: -4px;
  padding-bottom: 24px;
}
@media only screen and (min-width: 768px) {
  .c-publisher-footer__menu-group {
    padding-bottom: 0;
  }
}

.c-publisher-footer__heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.25em;
}

.c-publisher-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-publisher-footer__item {
  padding: 0.25em 0;
}

.c-publisher-footer__link,
.c-publisher-footer__link:visited {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  color: inherit;
  border: 0;
  padding: 0;
  background: none;
}

button.c-publisher-footer__link {
  text-decoration: underline;
}

.c-user-metadata {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  color: inherit;
  line-height: 1.4;
  padding-top: 24px;
}

.c-user-metadata__item {
  margin-top: 0;
  margin-bottom: 4px;
}
.c-user-metadata__item:last-child {
  margin-bottom: 0;
}

.c-product-header {
  background-color: white;
  border-radius: 2px;
  border: 1px solid rgba(151, 191, 216, 0.3);
  line-height: 1.3;
  padding: 16px;
  box-shadow: 0 0 5px 0 rgba(127.5, 127.5, 127.5, 0.1);
}

.c-product-header__theme {
  height: 50px;
  margin: -16px -16px 0;
  position: relative;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
}
@media only screen and (min-width: 1024px) {
  .c-product-header__theme::after {
    content: "";
    background-color: rgba(255, 255, 255, 0.15);
    width: 456px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.c-product-header__content {
  padding-top: 16px;
}
@media only screen and (min-width: 1024px) {
  .c-product-header__content {
    display: flex;
  }
}

.c-product-header__main {
  display: flex;
  flex: 1 1 auto;
}

@media only screen and (min-width: 1024px) {
  .c-product-header__side {
    flex: 1 0 440px;
    max-width: 440px;
    padding-left: 16px;
    margin-left: 16px;
  }
}

.c-product-header__side:not(:empty) {
  border-top: 1px solid rgba(151, 191, 216, 0.3);
  margin-top: 16px;
  padding-top: 16px;
}
@media only screen and (min-width: 1024px) {
  .c-product-header__side:not(:empty) {
    border-top: 0;
    border-left: 1px solid rgba(151, 191, 216, 0.3);
    margin-top: 0;
    padding-top: 0;
  }
}

.c-product-header__cover {
  margin-top: -50px;
  margin-right: 16px;
  position: relative;
  z-index: 5;
}
.c-product-header__cover img {
  box-shadow: 0 0 5px 2px rgba(127.5, 127.5, 127.5, 0.2);
  border: 2px solid white;
  border-radius: 4px;
  max-width: 96px;
  max-height: 125px;
}

.c-product-header__title {
  font-style: normal;
  line-height: 1.4;
  font-size: 2.25rem;
  font-size: min(max(1.75rem, 4vw), 2.25rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 1.875rem;
  margin-bottom: 0;
}
.c-product-header__title a.active, .c-product-header__title a:active, .c-product-header__title a.hover, .c-product-header__title a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.c-product-header__title a {
  color: inherit;
}
.c-product-header__title a.visited, .c-product-header__title a:visited {
  color: inherit;
}
.c-product-header__title a.hover, .c-product-header__title a:hover {
  color: inherit;
}

.c-product-header__subtitle {
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 0;
}

.c-product-header__imprint {
  max-height: 75px;
}

.c-image-copyright {
  position: relative;
}

.c-image-copyright__text {
  font-size: 1.1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0 4px;
  background-color: #fff;
  color: #666666;
}

/* grid-column-gap and grid-template-areas are duplicated in places. Do not remove the duplicates, this must be done for autoprefixer to correctly apply IE vendor prefixes */
.c-page-layout {
  display: grid;
}
.c-page-layout--sticky-footer {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-areas: "header" "body" "footer";
}
.c-page-layout--landing {
  grid-column-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-areas: "main" "side";
}
@media only screen and (min-width: 768px) {
  .c-page-layout--landing {
    grid-column-gap: 30px;
    grid-template-columns: 1fr 160px;
    grid-template-areas: "main    side";
  }
}
@media only screen and (min-width: 1024px) {
  .c-page-layout--landing {
    grid-column-gap: 50px;
    grid-template-columns: 1fr 190px;
    grid-template-areas: "main    side";
  }
}
.c-page-layout--default {
  grid-column-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-areas: "nav" "main" "side";
}
@media only screen and (min-width: 768px) {
  .c-page-layout--default {
    grid-column-gap: 30px;
    grid-template-columns: 160px 1fr;
    grid-template-areas: "nav     main" " .      side";
  }
}
@media only screen and (min-width: 1024px) {
  .c-page-layout--default {
    grid-column-gap: 50px;
    grid-template-columns: 190px 1fr;
    grid-template-areas: "nav     main" " .      side";
  }
}
@media only screen and (min-width: 1220px) {
  .c-page-layout--default {
    grid-column-gap: 50px;
    grid-template-columns: 190px 1fr 190px;
    grid-template-areas: "nav     main    side";
  }
}
.c-page-layout--fulltext {
  grid-column-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-areas: "nav" "main" "side";
}
@media only screen and (min-width: 768px) {
  .c-page-layout--fulltext {
    grid-column-gap: 30px;
    grid-template-columns: 1fr 160px;
    grid-template-areas: "main    side";
  }
}
@media only screen and (min-width: 1024px) {
  .c-page-layout--fulltext {
    grid-column-gap: 50px;
    grid-template-columns: 1fr 190px;
    grid-template-areas: "main    side";
  }
}
@media only screen and (min-width: 1220px) {
  .c-page-layout--fulltext {
    grid-column-gap: 50px;
    grid-template-columns: 190px 1fr 190px;
    grid-template-areas: "nav     main    side";
  }
}

.c-page-layout__nav {
  grid-area: nav;
}

.c-page-layout__main {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  grid-area: main;
  min-width: 0;
}

.c-page-layout__side {
  grid-area: side;
}
@media only screen and (max-width: 1023px) {
  .c-page-layout__side {
    margin-top: 24px;
  }
}

.c-page-layout__header {
  grid-area: header;
}

.c-page-layout__body {
  grid-area: body;
}

.c-page-layout__footer {
  grid-area: footer;
}

.c-popup-search {
  position: relative;
  z-index: 10;
  background-color: rgb(242.25, 242.25, 242.25);
  padding: 16px 0;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.21);
}
@media only screen and (min-width: 1024px) {
  .js .c-popup-search {
    position: absolute;
    width: 100%;
    top: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .c-popup-search__container {
    margin: auto;
    max-width: 70%;
  }
}

.intro--paragraph {
  padding-bottom: 16px;
  border-bottom: 1px solid #cccccc;
}

.divider--paragraph {
  border-top: 1px solid #cccccc;
  padding-top: 1.5em;
}

.float--right {
  float: right;
  margin-left: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .float--right {
    margin-left: 16px;
    margin-bottom: 16px;
  }
}

.float--left {
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .float--left {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.app-card-body {
  flex: 1 1 60%;
}

.app-card-image {
  aspect-ratio: auto;
  flex: 0 0 40%;
  max-width: 230px;
}
.app-card-image img {
  height: auto;
  min-height: 0;
  position: relative;
  transform: translateY(0);
}

.app-card-image-cover {
  aspect-ratio: auto;
  flex: 0 0 96px;
  max-width: 96px;
}
.app-card-image-cover img {
  box-shadow: 0 0 5px 2px rgba(127.5, 127.5, 127.5, 0.2);
  border: 2px solid #fff;
  border-radius: 4px;
  position: relative;
  height: auto;
  min-height: auto;
}

/**
 * This uses settings from the box component.
 * Version changes to box may causes this to error.
 */
.app-journal-header__theme {
  height: 50px;
  margin: -16px -16px 0;
  position: relative;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
}
@media only screen and (min-width: 1024px) {
  .app-journal-header__theme::after {
    content: "";
    background-color: rgba(255, 255, 255, 0.15);
    width: 456px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.app-journal-header__content {
  padding-top: 16px;
}
@media only screen and (min-width: 1024px) {
  .app-journal-header__content {
    display: flex;
  }
}

.app-journal-header__main {
  display: flex;
  flex: 1 1 auto;
}

@media only screen and (min-width: 1024px) {
  .app-journal-header__side {
    flex: 1 0 440px;
    max-width: 440px;
    padding-left: 16px;
    margin-left: 16px;
  }
}

.app-journal-header__side:not(:empty) {
  border-top: 1px solid #cccccc;
  margin-top: 16px;
  padding-top: 16px;
}
@media only screen and (min-width: 1024px) {
  .app-journal-header__side:not(:empty) {
    border-top: 0;
    border-left: 1px solid #cccccc;
    margin-top: 0;
    padding-top: 0;
  }
}

.app-journal-header__cover {
  margin-top: -50px;
  margin-right: 16px;
  position: relative;
  z-index: 5;
}
.app-journal-header__cover img {
  box-shadow: 0 0 5px 2px rgba(127.5, 127.5, 127.5, 0.2);
  border: 2px solid white;
  border-radius: 4px;
  max-width: 96px;
  max-height: 125px;
}

.app-journal-header__title {
  font-style: normal;
  line-height: 1.4;
  font-size: 2.25rem;
  font-size: min(max(1.75rem, 4vw), 2.25rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 1.875rem;
  margin-bottom: 0;
}
.app-journal-header__title a.active, .app-journal-header__title a:active, .app-journal-header__title a.hover, .app-journal-header__title a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.app-journal-header__title a {
  color: inherit;
}
.app-journal-header__title a.visited, .app-journal-header__title a:visited {
  color: inherit;
}
.app-journal-header__title a.hover, .app-journal-header__title a:hover {
  color: inherit;
}

.app-journal-header__subtitle {
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 0;
}

.app-journal-header__imprint {
  max-height: 75px;
}

.app-journal-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 16px;
}

.app-journal-nav__item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  display: inline-block;
  margin-bottom: 16px;
}
.app-journal-nav__item:not(:last-child) {
  margin-right: 24px;
}

.app-journal-nav__link {
  display: inline-flex;
  align-items: center;
}
.app-journal-nav__link.visited, .app-journal-nav__link:visited {
  color: #004b83;
}
.app-journal-nav__link.hover, .app-journal-nav__link:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.app-journal-nav__link svg {
  fill: currentColor;
  width: initial;
  height: initial;
}

.c-two-col-layout {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  height: auto;
}

@media only screen and (min-width: 1024px) {
  .c-two-col-layout--row-at-lg {
    flex-direction: row;
  }
}

@media only screen and (min-width: 1024px) {
  .c-two-col-layout__main {
    flex: 999 1 0%;
    margin-right: 64px;
  }
}

@media only screen and (min-width: 1024px) {
  .c-two-col-layout__side {
    flex: 1 0 454px;
  }
}

/**
 * These styles are for <table> and their permitted elements converted to <div> with class names that denote their
 * former semantic existence
 *
 * Initial placeholder styles taken from base tables.
 */
.jflow-table {
  display: table;
  width: 100%;
  margin-bottom: 32px;
}

.jflow-td,
.jflow-th {
  display: table-cell;
  vertical-align: top;
}

.jflow-th {
  text-align: left;
}

.jflow-td {
  vertical-align: top;
}
.jflow-td p:last-child {
  margin-bottom: 0;
}

.jflow-tr {
  display: table-row;
}

.app-journal-metrics {
  margin-bottom: 48px;
}
@media only screen and (min-width: 540px) {
  .app-journal-metrics {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    padding: 16px 16px 32px;
    box-shadow: 0 0 5px 0 rgba(127.5, 127.5, 127.5, 0.1);
  }
}

.app-journal-metrics__bg {
  display: none;
}
@media only screen and (min-width: 540px) {
  .app-journal-metrics__bg {
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin-left: -16px;
    margin-top: -16px;
  }
}

@media only screen and (min-width: 540px) {
  .app-journal-metrics__column {
    flex: 1;
    z-index: 2;
    font-size: 1rem;
    margin-left: 16px;
    padding-left: 16px;
  }
}

@media only screen and (min-width: 540px) {
  .app-journal-metrics__column:nth-of-type(1) {
    margin-left: 0;
    padding-left: 0;
    border-left: 0;
  }
}

@media only screen and (min-width: 540px) {
  .app-journal-metrics__column .app-journal-metrics__details:nth-child(1n+3) {
    margin-top: 16px;
  }
}

.app-journal-metrics__details {
  font-weight: 700;
  margin-top: 16px;
}
@media only screen and (min-width: 540px) {
  .app-journal-metrics__details {
    margin-top: 0;
  }
}

.c-list-societies {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  position: relative;
}
.c-list-societies::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fcfcfc;
}
@media only screen and (min-width: 540px) {
  .c-list-societies {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-list-societies__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  padding-top: 16px;
  padding-right: 0;
  padding-bottom: 16px;
  padding-left: 0;
}
.c-list-societies__item:first-child {
  margin-top: 0;
  border-top: 0;
}
@media only screen and (min-width: 540px) {
  .c-list-societies__item {
    flex: 1 1 50%;
    max-width: 50%;
  }
  .c-list-societies__item:nth-child(odd) {
    border-right: 1px solid #cccccc;
    padding-right: 16px;
  }
  .c-list-societies__item:nth-child(even) {
    padding-left: 16px;
    padding-right: 0;
  }
  .c-list-societies__item:only-child {
    border-right: 0;
    flex: 0 1 auto;
    justify-content: normal;
  }
}

.c-list-societies__text {
  margin-right: 16px;
  display: inline-block;
  flex: 1 1 auto;
  max-width: 100%;
}
@media only screen and (min-width: 540px) {
  .c-list-societies__item:only-child .c-list-societies__text {
    flex: 0 1 auto;
  }
}
.c-list-societies__text a.active, .c-list-societies__text a:active, .c-list-societies__text a.hover, .c-list-societies__text a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.c-list-societies__image {
  flex: 0 0 auto;
}
.c-list-societies__image img {
  width: 80px;
}

.c-journal-evaluation-section .c-list-group__item:first-child {
  padding-top: 0;
}
.c-journal-evaluation-section .c-list-group__item:last-child {
  padding-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .app-collections__item-container {
    display: flex;
    width: 100%;
  }
}

.app-collections-article__count {
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  vertical-align: middle;
  margin-left: 4px;
}

.app-collections-article__list img {
  border: 1px solid rgba(127.5, 127.5, 127.5, 0.2);
  max-width: 230px;
  max-height: 125px;
}

.app-collections-participating-journal__main {
  flex: 999 1 0%;
}

@media only screen and (max-width: 767px) {
  .app-collections--hidden-on-mobile {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
}

@media only screen and (min-width: 540px) {
  .app-collections-participating-journal__side {
    flex: 1 0 80px;
  }
}
@media only screen and (min-width: 768px) {
  .app-collections-participating-journal__side {
    flex: 1 0 175px;
  }
}

@media only screen and (min-width: 768px) {
  .app-collections__item {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  .app-collections-article {
    display: flex;
  }
}

.app-collections__item--separated {
  margin-bottom: 24px;
}
@media only screen and (min-width: 768px) {
  .app-collections__item--separated {
    display: flex;
    border-right: 1px solid #cccccc;
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .app-collections__item--separated:last-child {
    border-right: none;
  }
}

.app-collections__item--separated .c-list-description__item {
  margin-bottom: 0px;
}

.app-collections-article__main {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #cccccc;
}
@media only screen and (min-width: 768px) {
  .app-collections-article__main {
    margin-bottom: 0px;
    margin-right: 16px;
    padding-bottom: 0px;
    border-bottom: 0;
    padding-right: 16px;
    border-right: 1px solid #cccccc;
    flex: 999 1 0%;
  }
}

@media only screen and (min-width: 768px) {
  .app-collections-article__side {
    flex: 1 0 40%;
  }
}

.app-collections-guest-editors__item {
  display: flex;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #cccccc;
}

.app-collections-guest-editors__item:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

.js .app-collections-guest-editors__item {
  display: none;
}

.js .app-collections-guest-editors--show-all .app-collections-guest-editors__item,
.js .app-collections-guest-editors--visible {
  display: flex;
}

.app-collections-guest-editors__name {
  font-weight: 700;
}

.app-collections-guest-editors__button {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  cursor: pointer;
  margin: 0;
  background-color: transparent;
  border: 0;
  padding: 0;
  display: inline;
  width: auto;
}

.app-collections-guest-editors__image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  flex-shrink: 0;
}

.app-collections-guest-editors__list {
  color: #666666;
}

.app-collections-guest-editors__list--truncated > li:not(:only-child):last-child::before {
  content: ", ";
}
.app-collections-guest-editors__list--truncated > li:not(:only-child):last-child::after {
  content: "... ";
}

.app-collections-filter__label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.app-collections-filter__buttons {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-collections-filter__buttons > li {
  display: inline-block;
}
@media only screen and (max-width: 539px) {
  .app-collections-filter__buttons > li:not(:only-child):not(:last-child) {
    margin-bottom: 8px;
  }
}

.app-collections--open {
  color: #00b8b0;
}

.app-collections--closed {
  color: #666666;
}

.app-collections--upcoming {
  color: #edbc53;
}

.app-collection-metadata {
  border-top: 2px solid rgb(216.75, 216.75, 216.75);
  padding-top: 16px;
  margin-top: 16px;
  border-bottom: 2px solid rgb(216.75, 216.75, 216.75);
  padding-bottom: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1024px) {
  .app-collection-metadata {
    flex-direction: row;
  }
}

.app-collection-metadata__item {
  margin-bottom: 16px;
}
.app-collection-metadata > .app-collection-metadata__item:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .app-collection-metadata__item {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .app-collection-metadata > .app-collection-metadata__item:last-child {
    margin-right: 0;
  }
}

.app-section {
  margin-bottom: 48px;
}

.app-section__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-style: normal;
  line-height: 1.4;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
  border-bottom: 2px solid #666666;
  margin: 0 0 24px;
  padding: 0 0 8px;
}
.app-section__heading a.active, .app-section__heading a:active, .app-section__heading a.hover, .app-section__heading a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
@media only screen and (min-width: 1024px) {
  .app-section__heading {
    position: relative;
  }
}

@media only screen and (min-width: 540px) {
  .app-section__heading--space-between-at-sm {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    min-width: 0;
  }
}

@media only screen and (min-width: 540px) {
  .app-volumes-and-issues__content {
    display: flex;
  }
}

.app-volumes-and-issues__article-list {
  border-top: 1px solid #ccc;
  margin-top: 24px;
}

.app-volumes-and-issues__title {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 24px;
}

.app-volumes-and-issues__copy {
  font-size: 1rem;
  color: #666;
}

.app-volumes-and-issues__cover {
  display: none;
  position: relative;
  z-index: 5;
  margin-right: 16px;
}
.app-volumes-and-issues__cover img {
  box-shadow: 0 0 5px 2px rgba(127.5, 127.5, 127.5, 0.2);
  border: 2px solid white;
  border-radius: 4px;
  max-height: 182px;
}
@media only screen and (min-width: 540px) {
  .app-volumes-and-issues__cover {
    display: block;
    max-width: 20%;
    margin-right: 24px;
    flex: 0 0 auto;
  }
}

@media only screen and (min-width: 540px) {
  .app-volumes-and-issues__info {
    flex: 1 1 auto;
  }
}

.app-submission-guidelines__section {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 24px;
  padding-bottom: 24px;
}
.app-submission-guidelines__section li {
  word-wrap: break-word;
}
.app-submission-guidelines__section:last-child {
  border: 0;
}

.app-open-access__section {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 24px;
  padding-bottom: 24px;
}
.app-open-access__section li {
  word-wrap: break-word;
}
.app-open-access__section:last-child {
  border: 0;
}

.app-open-access-box__container {
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .app-open-access-box__container {
    display: flex;
    justify-content: space-between;
  }
}

.app-open-access-box__icon {
  margin-bottom: 16px;
}

.app-open-access-box__title {
  font-size: 1.125rem;
  line-height: 1.4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
}

.app-open-access-box__item {
  margin-bottom: 16px;
  background-color: rgb(229.5, 229.5, 229.5);
  padding: 16px;
}
.app-open-access-box__item:last-child {
  margin-bottom: 0px;
}
@media only screen and (min-width: 768px) {
  .app-open-access-box__item {
    margin-bottom: 0px;
    flex: 0 0 32%;
    max-width: 32%;
  }
}

.js article.c-card span[data-popup] {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}
.js article.c-card span[data-popup].visited, .js article.c-card span[data-popup]:visited {
  color: #a345c9;
}
.js article.c-card span[data-popup].hover, .js article.c-card span[data-popup]:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.js article.c-card span[data-popup].active, .js article.c-card span[data-popup]:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
.js article.c-card span[data-popup].focus, .js article.c-card span[data-popup]:focus {
  outline: 4px solid #ffcc00;
}

.app-latest-issue__cover {
  margin-right: 16px;
  position: relative;
  z-index: 5;
}
.app-latest-issue__cover img {
  box-shadow: 0 0 5px 2px rgba(127.5, 127.5, 127.5, 0.2);
  border: 2px solid white;
  border-radius: 4px;
  max-height: 175px;
}

.c-recent-articles__circle {
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: #e4f0ff;
  margin: 0 8px 4px 0;
}

.c-recent-articles__icon {
  fill: #004b83;
  margin-right: 8px;
}

.c-recent-articles__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  padding-top: 16px;
  margin-bottom: 24px;
  border-top: 1px solid #cccccc;
}

.app-collections-participating-journal__heading {
  font-size: 1.5rem;
}

.app-collections-participating-journal__item {
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px solid #cccccc;
}

.app-collections-participating-journal__item:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

.app-collections-participating-journal__title {
  font-size: 1.25rem;
}
.app-collections-participating-journal__title a {
  color: #333;
  text-decoration: underline;
}
.app-collections-participating-journal__title a:hover {
  text-decoration: none;
}

.app-collections-participating-journal__link {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 8px;
}
@media only screen and (min-width: 1024px) {
  .app-collections-participating-journal__link {
    align-items: center;
  }
}

.app-collections-participating-journal__image {
  flex: 0 0 96px;
  max-width: 96px;
  box-shadow: 0 0 5px 2px rgba(127.5, 127.5, 127.5, 0.2);
  border: 2px solid #fff;
  border-radius: 4px;
  max-height: 125px;
}

.app-collections-participating-journal__info {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 0 1 auto;
  padding-left: 16px;
}

.u-divider {
  border-bottom: 1px solid rgb(216.75, 216.75, 216.75);
}

.c-author-list--book-series {
  color: #666666;
}

.c-author-list--simple-comma-separated > li:not(:only-child):last-child::before {
  content: ", ";
}
.c-author-list--simple-comma-separated > li:not(:only-child):last-child::after {
  content: "...";
}

.app-checklist-banner {
  display: flex;
  flex: 1 1 auto;
  border: 2px solid #ebf1f5;
  font-size: 1rem;
  background-color: #fff;
}

.app-checklist-banner__title {
  font-size: 1.125rem;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 8px;
}

.app-checklist-banner__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 96px;
  width: 96px;
  background-color: #ebf1f5;
}

.app-checklist-banner__copy {
  margin-bottom: 8px;
}

.app-checklist-banner__link {
  display: inline-flex;
  align-items: center;
  color: #004b83;
}
.app-checklist-banner__link.visited, .app-checklist-banner__link:visited {
  color: #004b83;
}
.app-checklist-banner__link.hover, .app-checklist-banner__link:hover {
  color: #004b83;
}

.app-checklist-banner__body {
  padding: 16px;
}

.app-checklist-banner__paper-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 48px;
  height: 48px;
  width: 48px !important;
  height: 48px !important;
}

.app-checklist-banner__arrow-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 11px;
  margin: 4px 0 0 8px;
}

.app-conference-series-timeline {
  list-style: none;
  margin: 24px;
  padding: 0;
}
@media only screen and (min-width: 540px) {
  .app-conference-series-timeline {
    margin-left: 80px;
  }
}

.app-conference-series-timeline__year {
  position: relative;
  border-left: 2px dotted rgb(127.5, 127.5, 127.5);
  padding: 4px 8px 8px 24px;
}

.app-conference-series-timeline__year::before {
  content: " ";
  position: absolute;
  left: -13px;
  top: 8px;
  padding-left: 1.5em;
  height: 100%;
  background: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cpath d="M12 18a6 6 0 100-12 6 6 0 000 12zm0 2a8 8 0 110-16 8 8 0 010 16z" fill="%23FCFCFC" fill-rule="nonzero"/%3E%3Cpath d="M12 16a4 4 0 100-8 4 4 0 000 8zm0 2a6 6 0 110-12 6 6 0 010 12z" fill="%23B3B3B3" fill-rule="nonzero"/%3E%3Ccircle fill="%23FCFCFC" cx="12" cy="12" r="4"/%3E%3C/g%3E%3C/svg%3E') no-repeat;
}

.app-conference-series-timeline__marker {
  display: block;
  padding-top: 2px;
}
@media only screen and (min-width: 540px) {
  .app-conference-series-timeline__marker {
    position: absolute;
    left: -100px;
    top: 4px;
    width: 80px;
    text-align: right;
  }
}

.app-conference-series-timeline__heading {
  margin: 0 0 8px;
}

.app-conference-series-timeline__inline-list {
  margin: 0 0 4px;
  padding: 0;
}

.app-conference-series-timeline__inline-list > li {
  display: inline;
}

.app-conference-series-timeline__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-conference-series-timeline__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #cccccc;
  margin: 0 0 8px;
  padding: 8px 16px;
}

.app-conference-series-timeline__item-title {
  margin: 0 0 8px;
  flex-basis: 100%;
  flex-grow: 1;
}
@media only screen and (min-width: 768px) {
  .app-conference-series-timeline__item-title {
    margin: 0 auto 0 0;
    flex-basis: 0;
  }
}

.app-conference-series-timeline__item-count {
  flex-grow: 1;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .app-conference-series-timeline__item-count {
    flex-grow: 0;
    border-left: 1px solid #cccccc;
    margin: 0 8px;
    padding: 8px;
  }
}

.app-conference-series-timeline__item-count + .app-conference-series-timeline__item-count {
  border-left: 1px solid #cccccc;
  padding-left: 8px;
}

.app-conference-series-timeline__item-count > span {
  display: block;
}

.app-conference-series-timeline__item-count-value {
  font-weight: 700;
}

.c-collection-editors {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-collection-editors__ampersand,
.c-collection-editors__comma {
  display: none;
}

.js .c-collection-editors__item,
.js .c-collection-editors__ampersand,
.js .c-collection-editors__comma {
  display: inline-block;
}

.js .c-collection-editors__name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;
}

.c-collection-editors__name a {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  text-decoration: underline;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.08em;
}
.c-collection-editors__name a:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.3875rem;
  text-underline-offset: 0.08em;
}

.c-collection-editor__popup h3 {
  display: none;
}

.c-collection-editors__image {
  margin-right: 16px;
  margin-bottom: 8px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.js .c-popup.c-collection-editor__popup {
  font-size: 1rem;
}

.js .c-collection-editor__popup h3 {
  display: block;
  margin-bottom: 8px;
}

.js .c-collection-editor__popup p {
  margin: 0;
  white-space: pre-line;
}

.app-footer {
  background-color: #fcfcfc;
  color: rgb(63.75, 63.75, 63.75);
}

.app-footer-edition-list a,
.app-footer-edition-list a:active,
.app-footer-edition-list a:hover,
.app-footer-edition-list a:visited,
.app-footer__copyright a,
.app-footer__copyright a:active,
.app-footer__copyright a:hover,
.app-footer__copyright a:visited,
.app-footer__nav a,
.app-footer__nav a:active,
.app-footer__nav a:hover,
.app-footer__nav a:visited {
  color: rgb(63.75, 63.75, 63.75);
}

.app-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  padding-top: 16px;
  padding-bottom: 16px;
}

.app-footer__aside-wrapper {
  border: 1px solid #cccccc;
  border-left: 0;
  border-right: 0;
}

.app-footer__strapline {
  font-size: 1.0625rem;
  display: block;
  margin-top: 0;
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .app-footer__strapline {
    display: inline-block;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .app-footer__edition {
    float: right;
    margin-bottom: 0;
  }
}

.app-footer-edition-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-footer-edition-list li {
  display: inline-block;
}
.app-footer-edition-list li:first-child {
  border-right: 1px solid #cccccc;
  margin-right: 0.5em;
  padding-right: 0.5em;
}
.app-footer-edition-list li.selected a {
  font-weight: 700;
}

.app-footer__nav {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0 0 16px;
}
.app-footer__nav li {
  display: inline-block;
  border-right: 1px solid #cccccc;
  margin-right: 0.5em;
  padding-right: 0.5em;
}
.app-footer__nav li:last-child {
  border-right: none;
  padding-right: 0;
}

.app-footer__parent-logo {
  display: inline-block;
  margin-bottom: 16px;
}

.app-footer__copyright,
.app-footer__user-access-info {
  font-size: 0.75rem;
  margin: 0;
}

.app-promo-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.app-promo-text > .jflow-table:last-of-type {
  margin-bottom: 0;
}
.app-promo-text b {
  font-weight: 500;
}

.app-promo-text--keyline {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.app-promo-text__hidden-container {
  margin-bottom: 16px;
}
.app-promo-text__hidden-container > *:last-child {
  margin-bottom: 0;
}

.app-promo-text__hidden-container--padding {
  padding-top: 1.5em;
  margin-block-start: 0;
}

.app-promo-text__inline {
  display: inline;
}

.app-promo-text__emdash {
  font-weight: 400;
}

.app-promo-text__button {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-weight: 400;
  display: inline;
  width: auto;
}
.app-promo-text__button.visited, .app-promo-text__button:visited {
  color: #a345c9;
}
.app-promo-text__button.hover, .app-promo-text__button:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.app-promo-text__button.active, .app-promo-text__button:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
.app-promo-text__button.focus, .app-promo-text__button:focus {
  outline: 4px solid #ffcc00;
}

.app-search fieldset {
  border: none;
}

.app-search__content {
  display: flex;
}

.app-search__label {
  font-size: 0.875rem;
  display: inline-block;
  color: #666;
  margin-bottom: 8px;
}

.app-search__input {
  font-size: 0.875rem;
  border: 1px solid #b3b3b3;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  vertical-align: middle;
  line-height: 1.2;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.21);
  padding: 0.75em 1em;
  width: 100%;
  flex: 0 1 auto;
}

.app-search__button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: white;
  background-color: rgb(51, 97.9333333333, 156.6);
  background-image: linear-gradient(to bottom, rgb(76.5, 117.5666666667, 168.9), rgb(51, 97.9333333333, 156.6));
  border: 1px solid rgba(0, 58.6666666667, 132, 0.5);
  width: 50px;
  text-align: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.app-search__button svg {
  fill: currentColor;
}
.app-search__button:visited {
  color: white;
}
.app-search__button:hover {
  border: 1px solid rgb(0, 96.6984732824, 168.9);
}
.app-search__button:focus {
  border: 1px solid rgb(0, 96.6984732824, 168.9);
}
.app-search__button:hover, .app-search__button:focus {
  color: white;
  background-color: rgb(0, 96.6984732824, 168.9);
  background-image: none;
}
.app-search__button:hover svg path, .app-search__button:focus svg path {
  fill: white;
}

.app-search__icon--outline {
  border-radius: 50%;
  border: 1px solid currentColor;
  padding: 0.2em;
}

@media only screen and (min-width: 540px) and (max-width: 1023px) {
  .app-sidebar-boxes {
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px;
  }
  .app-sidebar-boxes > * {
    flex: 1 0 250px;
    max-width: calc(50% - 24px);
    margin-left: 24px;
  }
}

.app-elements .c-header {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  line-height: 1.4;
  background-color: #fff;
  padding: 8px 0 0;
  border-bottom: 2px solid #01324b;
  font-size: 16px;
}
.app-elements .c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto 8px;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px 16px;
}
.app-elements .c-header__nav {
  border-top: 2px solid #cedbe0;
  padding-top: 4px;
  position: relative;
}
.app-elements .c-header__nav-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.app-elements .c-header__nav-container > *:not(:last-child) {
  margin-right: 32px;
}
.app-elements .c-header__link-container {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px 16px;
  justify-content: space-between;
}
.app-elements .c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-elements .c-header__list-item {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-weight: bold;
  padding: 8px;
}
.app-elements .c-header__list-item:not(:last-child) {
  border-bottom: 2px solid #cedbe0;
}
.app-elements .c-header__item {
  color: inherit;
}
@media only screen and (min-width: 540px) {
  .app-elements .c-header__item--menu {
    display: none;
    visibility: hidden;
  }
  .app-elements .c-header__item--menu:first-child + * {
    margin-block-start: 0;
  }
}
.app-elements .c-header__item--inline-links {
  display: none;
  visibility: hidden;
}
.app-elements .c-header__item--inline-links:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 540px) {
  .app-elements .c-header__item--inline-links {
    display: flex;
    gap: 16px;
    visibility: visible;
  }
}
.app-elements .c-header__item--divider:before {
  content: "";
  position: absolute;
  border-left: 2px solid #cedbe0;
  height: calc(100% - 16px);
  top: 8px;
  margin-left: -15px;
}
.app-elements .c-header__brand a {
  display: block;
  line-height: 1;
  padding: 16px 8px;
  text-decoration: none;
}
.app-elements .c-header__brand img {
  height: 24px;
  width: auto;
}
.app-elements .c-header__link {
  display: inline-block;
  padding: 16px 8px;
  color: inherit;
  font-weight: bold;
  text-decoration-color: transparent;
  position: relative;
  word-break: normal;
  white-space: nowrap;
}
.app-elements .c-header__link:visited {
  color: inherit;
}
.app-elements .c-header__link:hover {
  color: inherit;
  text-decoration-color: inherit;
}
.app-elements .c-header__link--static {
  flex: 0 0 auto;
}
.app-elements .c-header__link.is-open {
  color: #025e8d;
}
.app-elements .c-header__link.is-open::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}
.app-elements .c-header__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  font-size: 24px;
}
.app-elements .c-header__icon + * {
  margin-left: 8px;
}
.app-elements .c-header__expander {
  background-color: #ebf1f5;
}
.app-elements .c-header__search {
  padding: 24px 0;
}
@media only screen and (min-width: 540px) {
  .app-elements .c-header__search {
    max-width: 70%;
  }
}
.app-elements .c-header__search-container {
  position: relative;
}
.app-elements .c-header__search-label {
  display: inline-block;
  color: inherit;
  font-weight: bold;
  margin-bottom: 8px;
}
.app-elements .c-header__search-input {
  border: 1px solid black;
  width: 100%;
  padding: 8px 48px 8px 8px;
  background-color: white;
}
.app-elements .c-header__search-button {
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 0;
}
.app-elements .has-tethered.c-header__expander {
  margin-top: -2px;
  width: 100%;
  top: 100%;
  z-index: 10;
  left: 0;
  border-bottom: 2px solid #01324b;
}
@media only screen and (min-width: 540px) {
  .app-elements .has-tethered.c-header__expander--menu {
    display: none;
    visibility: hidden;
  }
  .app-elements .has-tethered.c-header__expander--menu:first-child + * {
    margin-block-start: 0;
  }
}
.app-elements .has-tethered.c-header__expander--account {
  transform: translateY(8px);
  width: auto;
  left: auto;
  position: absolute;
  right: 0;
  bottom: auto;
  margin-top: 0;
}
.app-elements .has-tethered .c-header__heading {
  display: none;
  visibility: hidden;
}
.app-elements .has-tethered .c-header__heading:first-child + * {
  margin-block-start: 0;
}
.app-elements .has-tethered .c-header__search {
  margin: auto;
}
.app-elements .c-header__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 16px 16px 0;
}

/**
 * 60-Utilities
 */
/**
 * Button utilities
 * These are for styled branded buttons
 */
.u-button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  position: relative;
  text-decoration: none;
  width: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  justify-content: center;
  padding: 8px;
  transition: 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  border-radius: 2px;
  color: #004b83;
  background-color: rgb(242.25, 242.25, 242.25);
  background-image: linear-gradient(to bottom, white, rgb(242.25, 242.25, 242.25));
  border: 1px solid #cccccc;
}
.u-button svg {
  fill: currentColor;
}
.u-button:visited {
  color: #004b83;
}
.u-button:hover {
  border: 1px solid #666666;
  text-decoration: none;
}
.u-button:focus {
  border: 1px solid #666666;
  text-decoration: none;
}
.u-button:hover, .u-button:focus {
  color: white;
  background-color: #666666;
  background-image: none;
}
.u-button:hover svg path, .u-button:focus svg path {
  fill: white;
}

.u-button--primary {
  color: white;
  background-color: rgb(51, 97.9333333333, 156.6);
  background-image: linear-gradient(to bottom, rgb(76.5, 117.5666666667, 168.9), rgb(51, 97.9333333333, 156.6));
  border: 1px solid rgba(0, 58.6666666667, 132, 0.5);
}
.u-button--primary svg {
  fill: currentColor;
}
.u-button--primary:visited {
  color: white;
}
.u-button--primary:hover {
  border: 1px solid rgb(0, 96.6984732824, 168.9);
}
.u-button--primary:focus {
  border: 1px solid rgb(0, 96.6984732824, 168.9);
}
.u-button--primary:hover, .u-button--primary:focus {
  color: white;
  background-color: rgb(0, 96.6984732824, 168.9);
  background-image: none;
}
.u-button--primary:hover svg path, .u-button--primary:focus svg path {
  fill: white;
}

.u-button--contrast {
  color: white;
  background-color: transparent;
  background-image: none;
  border: 1px solid white;
  font-weight: normal;
}
.u-button--contrast svg {
  fill: currentColor;
}
.u-button--contrast:visited {
  color: white;
}
.u-button--contrast:hover {
  border: 1px solid white;
}
.u-button--contrast:focus {
  border: 1px solid white;
}
.u-button--contrast:hover, .u-button--contrast:focus {
  color: black;
  background-color: white;
  background-image: none;
}
.u-button--contrast:hover svg path, .u-button--contrast:focus svg path {
  fill: black;
}

.u-button--ghost {
  color: #333333;
  background-color: transparent;
  background-image: none;
}
.u-button--ghost svg {
  fill: currentColor;
}
.u-button--ghost:visited {
  color: #333333;
}
.u-button--ghost:hover, .u-button--ghost:focus {
  color: #333333;
  background-color: rgba(242.25, 242.25, 242.25, 0.5);
  background-image: none;
}
.u-button--ghost:hover svg path, .u-button--ghost:focus svg path {
  fill: #333333;
}

.u-button--disabled,
.u-button:disabled {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border: 1px solid #cccccc;
  opacity: 0.67;
  cursor: default;
}
.u-button--disabled svg,
.u-button:disabled svg {
  fill: currentColor;
}
.u-button--disabled:visited,
.u-button:disabled:visited {
  color: #333333;
}
.u-button--disabled:hover,
.u-button:disabled:hover {
  border: 1px solid #cccccc;
  text-decoration: none;
}
.u-button--disabled:focus,
.u-button:disabled:focus {
  border: 1px solid #cccccc;
  text-decoration: none;
}
.u-button--disabled:hover, .u-button--disabled:focus,
.u-button:disabled:hover,
.u-button:disabled:focus {
  color: #333333;
  background-color: transparent;
  background-image: none;
}
.u-button--disabled:hover svg path, .u-button--disabled:focus svg path,
.u-button:disabled:hover svg path,
.u-button:disabled:focus svg path {
  fill: #333333;
}

.u-button--xsmall {
  font-size: 0.875rem;
  padding: 2px;
}

.u-button--small {
  font-size: 0.875rem;
  padding: inherit;
}

.u-button--large {
  font-size: 1rem;
  padding: 16px;
}

.u-button--full-width {
  display: flex;
  width: 100%;
}

.u-button--icon-left svg {
  margin-right: 8px;
}

.u-button--icon-right svg {
  margin-left: 8px;
}

.u-clearfix::before, .u-clearfix::after {
  content: "";
  display: table;
}
.u-clearfix::after {
  clear: both;
}

.u-color-open-access {
  color: #b74616;
}

.u-color-inherit {
  color: inherit;
}

.u-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.u-display-block {
  display: block;
}

.u-display-inline {
  display: inline;
}

.u-display-inline-block {
  display: inline-block;
}

.u-display-flex {
  display: flex;
  width: 100%;
}

.u-display-inline-flex {
  display: inline-flex;
}

.u-display-grid {
  display: grid;
}

.u-display-table {
  display: table;
}

.u-flex-direction-row {
  flex-direction: row;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-baseline {
  align-items: baseline;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-left {
  justify-content: left;
}

.u-flex-grow {
  flex: 1 0 auto;
}

.u-flex-grow-shrink {
  flex: 1 1 auto;
}

.u-flex-shrink {
  flex: 0 1 auto;
}

.u-flex-static {
  flex: 0 0 auto;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-start {
  align-self: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-display-none {
  display: none;
}

/* hide from both screenreaders and browsers */
.u-hide,
.js .u-js-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + *,
.js .u-js-hide:first-child + * {
  margin-block-start: 0;
}

/* show to both screenreaders and browsers */
.u-show,
.js .u-js-show {
  display: block;
  visibility: visible;
}

.u-show-inline,
.js .u-js-show-inline {
  display: inline;
  visibility: visible;
}

/* hide only visually, but have it available for screenreaders */
.u-visually-hidden,
.js .u-js-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.u-visually-hidden--off,
.js .u-js-visually-hidden--off {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}

/* make invisible but retain dimensions */
.u-invisible {
  visibility: hidden;
}

/* hide only the text, keep element visible */
.u-hide-text,
.js .u-js-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  vertical-align: bottom;
}

/* hiding overflow */
.u-hide-overflow {
  overflow: hidden;
}

@media print {
  .u-hide-print {
    display: none;
  }
}
/**
 * media-query hiding
 * intentionally avoids 'max' and 'range' to prevent bloat
 */
@media only screen and (min-width: 320px) {
  .u-hide-at-xs,
  .js .u-js-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + *,
  .js .u-js-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xs,
  .js .u-js-show-at-xs {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 540px) {
  .u-hide-at-sm,
  .js .u-js-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + *,
  .js .u-js-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-sm,
  .js .u-js-show-at-sm {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-md,
  .js .u-js-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + *,
  .js .u-js-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-md,
  .js .u-js-show-at-md {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg,
  .js .u-js-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + *,
  .js .u-js-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-lg,
  .js .u-js-show-at-lg {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl,
  .js .u-js-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + *,
  .js .u-js-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xl,
  .js .u-js-show-at-xl {
    display: block;
    visibility: visible;
  }
}
.u-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
}

.u-link-inherit {
  color: inherit;
}
.u-link-inherit.visited, .u-link-inherit:visited {
  color: inherit;
}
.u-link-inherit.hover, .u-link-inherit:hover {
  color: inherit;
}

.u-link-underline {
  text-decoration: underline;
}

.u-link-faux-block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-list-style-number {
  list-style-type: decimal;
}

.u-list-style-bullet {
  list-style-type: disc;
}

.u-list-style-dash > ::marker {
  content: "– ";
  font-size: 1.2em;
  line-height: 1;
}

.u-list-style-none {
  list-style-type: none;
}

.u-list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

.u-custom-list-number {
  float: left;
  width: 3em;
  padding-right: 0.5em;
  margin-left: -3.5em;
  text-align: right;
}

.u-list-comma-separated {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-comma-separated > li {
  display: inline;
}
.u-list-comma-separated > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.u-list-comma-separated > li:not(:only-child):last-child::before {
  content: " & ";
}

.u-list-truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.u-position-top {
  top: 0;
}

.u-position-left {
  left: 0;
}

.u-position-bottom {
  bottom: 0;
}

.u-position-right {
  right: 0;
}

.u-position-negative-right {
  right: 100%;
}

.u-ma-0 {
  margin: 0;
}

.u-ma-2 {
  margin: 2px;
}

.u-ma-4 {
  margin: 4px;
}

.u-ma-8 {
  margin: 8px;
}

.u-ma-16 {
  margin: 16px;
}

.u-ma-24 {
  margin: 24px;
}

.u-ma-32 {
  margin: 32px;
}

.u-ma-48 {
  margin: 48px;
}

.u-ma-64 {
  margin: 64px;
}

.u-ma-auto {
  margin: auto;
}

.u-mt-0 {
  margin-top: 0;
}

.u-mt-2 {
  margin-top: 2px;
}

.u-mt-4 {
  margin-top: 4px;
}

.u-mt-8 {
  margin-top: 8px;
}

.u-mt-16 {
  margin-top: 16px;
}

.u-mt-24 {
  margin-top: 24px;
}

.u-mt-32 {
  margin-top: 32px;
}

.u-mt-48 {
  margin-top: 48px;
}

.u-mt-64 {
  margin-top: 64px;
}

.u-mt-auto {
  margin-top: auto;
}

.u-mr-0 {
  margin-right: 0;
}

.u-mr-2 {
  margin-right: 2px;
}

.u-mr-4 {
  margin-right: 4px;
}

.u-mr-8 {
  margin-right: 8px;
}

.u-mr-16 {
  margin-right: 16px;
}

.u-mr-24 {
  margin-right: 24px;
}

.u-mr-32 {
  margin-right: 32px;
}

.u-mr-48 {
  margin-right: 48px;
}

.u-mr-64 {
  margin-right: 64px;
}

.u-mr-auto {
  margin-right: auto;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-2 {
  margin-bottom: 2px;
}

.u-mb-4 {
  margin-bottom: 4px;
}

.u-mb-8 {
  margin-bottom: 8px;
}

.u-mb-16 {
  margin-bottom: 16px;
}

.u-mb-24 {
  margin-bottom: 24px;
}

.u-mb-32 {
  margin-bottom: 32px;
}

.u-mb-48 {
  margin-bottom: 48px;
}

.u-mb-64 {
  margin-bottom: 64px;
}

.u-mb-auto {
  margin-bottom: auto;
}

.u-ml-0 {
  margin-left: 0;
}

.u-ml-2 {
  margin-left: 2px;
}

.u-ml-4 {
  margin-left: 4px;
}

.u-ml-8 {
  margin-left: 8px;
}

.u-ml-16 {
  margin-left: 16px;
}

.u-ml-24 {
  margin-left: 24px;
}

.u-ml-32 {
  margin-left: 32px;
}

.u-ml-48 {
  margin-left: 48px;
}

.u-ml-64 {
  margin-left: 64px;
}

.u-ml-auto {
  margin-left: auto;
}

.u-pa-0 {
  padding: 0;
}

.u-pa-2 {
  padding: 2px;
}

.u-pa-4 {
  padding: 4px;
}

.u-pa-8 {
  padding: 8px;
}

.u-pa-16 {
  padding: 16px;
}

.u-pa-24 {
  padding: 24px;
}

.u-pa-32 {
  padding: 32px;
}

.u-pa-48 {
  padding: 48px;
}

.u-pa-64 {
  padding: 64px;
}

.u-pt-0 {
  padding-top: 0;
}

.u-pt-2 {
  padding-top: 2px;
}

.u-pt-4 {
  padding-top: 4px;
}

.u-pt-8 {
  padding-top: 8px;
}

.u-pt-16 {
  padding-top: 16px;
}

.u-pt-24 {
  padding-top: 24px;
}

.u-pt-32 {
  padding-top: 32px;
}

.u-pt-48 {
  padding-top: 48px;
}

.u-pt-64 {
  padding-top: 64px;
}

.u-pr-0 {
  padding-right: 0;
}

.u-pr-2 {
  padding-right: 2px;
}

.u-pr-4 {
  padding-right: 4px;
}

.u-pr-8 {
  padding-right: 8px;
}

.u-pr-16 {
  padding-right: 16px;
}

.u-pr-24 {
  padding-right: 24px;
}

.u-pr-32 {
  padding-right: 32px;
}

.u-pr-48 {
  padding-right: 48px;
}

.u-pr-64 {
  padding-right: 64px;
}

.u-pb-0 {
  padding-bottom: 0;
}

.u-pb-2 {
  padding-bottom: 2px;
}

.u-pb-4 {
  padding-bottom: 4px;
}

.u-pb-8 {
  padding-bottom: 8px;
}

.u-pb-16 {
  padding-bottom: 16px;
}

.u-pb-24 {
  padding-bottom: 24px;
}

.u-pb-32 {
  padding-bottom: 32px;
}

.u-pb-48 {
  padding-bottom: 48px;
}

.u-pb-64 {
  padding-bottom: 64px;
}

.u-pl-0 {
  padding-left: 0;
}

.u-pl-2 {
  padding-left: 2px;
}

.u-pl-4 {
  padding-left: 4px;
}

.u-pl-8 {
  padding-left: 8px;
}

.u-pl-16 {
  padding-left: 16px;
}

.u-pl-24 {
  padding-left: 24px;
}

.u-pl-32 {
  padding-left: 32px;
}

.u-pl-48 {
  padding-left: 48px;
}

.u-pl-64 {
  padding-left: 64px;
}

.u-keyline {
  border-bottom: 1px solid rgb(216.75, 216.75, 216.75);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-keyline--thick {
  border-bottom: 2px solid rgb(216.75, 216.75, 216.75);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-shadow {
  border: 1px solid black;
  box-shadow: 5px 5px 5px black;
}

.u-button-reset {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.u-monospace {
  font-family: monospace;
}

.u-sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.u-serif {
  font-family: Georgia, Palatino, serif;
}

.u-small-caps {
  font-size: 0.8em; /* scale based on containing text */
  text-transform: uppercase;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

.u-double-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  border-bottom: 1px solid;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.u-upper {
  text-transform: uppercase;
}

.u-lower {
  text-transform: lowercase;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}

.u-unstyled {
  font-weight: normal;
  font-style: normal;
}

.u-h1 {
  font-style: normal;
  line-height: 1.4;
  font-size: 2.25rem;
  font-size: min(max(1.75rem, 4vw), 2.25rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h1 a.active, .u-h1 a:active, .u-h1 a.hover, .u-h1 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h2 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h2 a.active, .u-h2 a:active, .u-h2 a.hover, .u-h2 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h3 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h3 a.active, .u-h3 a:active, .u-h3 a.hover, .u-h3 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h4 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 2.5vw), 1.25rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
.u-h4 a.active, .u-h4 a:active, .u-h4 a.hover, .u-h4 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-text-xs {
  font-size: 0.875rem;
}

.u-text-sm {
  font-size: 1rem;
}

.u-text-md {
  font-size: 1.25rem;
}

.u-text-lg {
  font-size: 1.5rem;
}

.u-text-sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.u-text-serif {
  font-family: Georgia, Palatino, serif;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-text-right {
  text-align: right;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

/**
 * Classes used to maintain the semantically appropriate heading levels
 */
.u-h1 {
  font-style: normal;
  line-height: 1.4;
  font-size: 2.25rem;
  font-size: min(max(1.75rem, 4vw), 2.25rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h1 a.active, .u-h1 a:active, .u-h1 a.hover, .u-h1 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h2 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.75rem;
  font-size: min(max(1.5rem, 3.5vw), 1.75rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h2 a.active, .u-h2 a:active, .u-h2 a.hover, .u-h2 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h3 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.5rem;
  font-size: min(max(1.25rem, 3vw), 1.5rem);
  font-family: Georgia, Palatino, serif;
  font-weight: 400;
}
.u-h3 a.active, .u-h3 a:active, .u-h3 a.hover, .u-h3 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h4 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.25rem;
  font-size: min(max(1.125rem, 2.5vw), 1.25rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
.u-h4 a.active, .u-h4 a:active, .u-h4 a.hover, .u-h4 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-h5 {
  font-style: normal;
  line-height: 1.4;
  font-size: 1.125rem;
  font-size: min(max(1rem, 2.5vw), 1.125rem);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700;
}
.u-h5 a.active, .u-h5 a:active, .u-h5 a.hover, .u-h5 a:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.u-vh-full {
  min-height: 100vh;
}

.u-color-success {
  color: #71ab0b;
}

.u-color-info {
  color: #004b83;
}

.u-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + * {
  margin-block-start: 0;
}

@media only screen and (min-width: 320px) {
  .u-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (min-width: 540px) {
  .u-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 319px) {
  .u-hide-at-lt-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-xs:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 539px) {
  .u-hide-at-lt-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-sm:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 767px) {
  .u-hide-at-lt-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-md:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .u-hide-at-lt-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-lg:first-child + * {
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 1219px) {
  .u-hide-at-lt-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lt-xl:first-child + * {
    margin-block-start: 0;
  }
}
.u-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -100%;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

@media only screen and (min-width: 320px) {
  .u-visually-hidden-at-xs {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (min-width: 540px) {
  .u-visually-hidden-at-sm {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (min-width: 768px) {
  .u-visually-hidden-at-md {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .u-visually-hidden-at-lg {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (min-width: 1220px) {
  .u-visually-hidden-at-xl {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 319px) {
  .u-visually-hidden-at-lt-xs {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 539px) {
  .u-visually-hidden-at-lt-sm {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 767px) {
  .u-visually-hidden-at-lt-md {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 1023px) {
  .u-visually-hidden-at-lt-lg {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
@media only screen and (max-width: 1219px) {
  .u-visually-hidden-at-lt-xl {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -100%;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
.u-visually-hidden-focus:active, .u-visually-hidden-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
  margin: 0;
}

/**
 * Default link style
 *
 */
.u-base-link {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.u-base-link.visited, .u-base-link:visited {
  color: #a345c9;
}
.u-base-link.hover, .u-base-link:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.u-base-link.active, .u-base-link:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
.u-base-link.focus, .u-base-link:focus {
  outline: 4px solid #ffcc00;
}

/**
 * Interface links
 *
 */
.u-interface-link.active, .u-interface-link:active, .u-interface-link.hover, .u-interface-link:hover {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

/**
 * Alternate link style
 *
 */
.u-gray-link {
  color: #666666;
}
.u-gray-link.visited, .u-gray-link:visited, .u-gray-link.hover, .u-gray-link:hover {
  color: #666666;
}

.u-deemphasised-link {
  color: #333333;
}
.u-deemphasised-link.visited, .u-deemphasised-link:visited {
  color: #333333;
}

/**
 * Unvisited
 * Override visited color
 *
 */
.u-unvisited.visited, .u-unvisited:visited {
  color: #004b83;
}
.u-unvisited.hover, .u-unvisited:hover {
  color: rgb(0, 96.6984732824, 168.9);
}

/**
 * Link Inherit
 * Make links inherit color from parent
 *
 */
.u-link-inherit {
  color: inherit;
}
.u-link-inherit.visited, .u-link-inherit:visited {
  color: inherit;
}
.u-link-inherit.hover, .u-link-inherit:hover {
  color: inherit;
}

/**
 * Link Like
 * Make non-anchor tags appear as links
 *
 */
.u-link-like {
  color: #004b83;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}
.u-link-like.visited, .u-link-like:visited {
  color: #a345c9;
}
.u-link-like.hover, .u-link-like:hover {
  color: rgb(0, 96.6984732824, 168.9);
}
.u-link-like.active, .u-link-like:active {
  color: rgb(0, 58.6666666667, 132);
  text-decoration: none;
}
.u-link-like.focus, .u-link-like:focus {
  outline: 4px solid #ffcc00;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

@media only screen and (min-width: 540px) {
  .u-display-flex-at-sm {
    display: flex;
  }
}
body {
  font-size: 1.125rem;
}

p {
  margin-bottom: 1.5em;
}

.c-listing p {
  margin-bottom: 0;
}

.c-header__item .c-dropdown__button {
  margin: 0;
}

.c-header__cart-icon {
  margin-right: 12px;
}

.c-header__navigation {
  display: flex;
  gap: 1rem;
}

/* Darwin header
/* temp fix for over specified selector beating utility class
 * remove when featureFlags.darwin-universal-header is not needed
 */
@media only screen and (max-width: 1023px) {
  .app-frontend-toolkit .u-hide-at-lt-lg {
    display: none;
    visibility: hidden;
  }
  .app-frontend-toolkit .u-hide-at-lt-lg:first-child + * {
    margin-block-start: 0;
  }
}

/* Darwin header */
.c-account-nav__anchor.is-open.eds-c-header__link::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzAyNWU4ZCIgaWQ9ImVkcy1pLXVzZXItc2luZ2xlLW1lZGl1bSI+PHBhdGggZD0iTTEyIDFhNSA1IDAgMSAxIDAgMTAgNSA1IDAgMCAxIDAtMTBabTAgMmEzIDMgMCAxIDAgMCA2IDMgMyAwIDAgMCAwLTZabS0uNDA2IDkuMDA4YTguOTY1IDguOTY1IDAgMCAxIDYuNTk2IDIuNDk0QTkuMTYxIDkuMTYxIDAgMCAxIDIxIDIxLjAyNVYyMmExIDEgMCAwIDEtMSAxSDRhMSAxIDAgMCAxLTEtMXYtLjk4NWMuMDUtNC44MjUgMy44MTUtOC43NzcgOC41OTQtOS4wMDdabS4zOSAxLjk5Mi0uMjk5LjAwNmMtMy42My4xNzUtNi41MTggMy4xMjctNi42NzggNi43NzVMNSAyMWgxMy45OThsLS4wMDktLjI2OGE3LjE1NyA3LjE1NyAwIDAgMC0xLjk3LTQuNTczbC0uMjE0LS4yMTNBNi45NjcgNi45NjcgMCAwIDAgMTEuOTg0IDE0WiIvPjwvc3ZnPgo=");
}

.u-full-height {
  height: 100%;
}