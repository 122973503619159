.app-search fieldset {
	border: none;
}

.app-search__content {
	display: flex;
}

.app-search__label {
	font-size: .875rem;
	display: inline-block;
	color: #666;
	margin-bottom: spacing(8);
}

.app-search__input {
	font-size: .875rem;
	border: 1px solid #b3b3b3;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	vertical-align: middle;
	line-height: 1.2;
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.21);
	padding: 0.75em 1em;
	width: 100%;
	flex: 0 1 auto;
}

.app-search__button {
	@include button($context--button-primary);
	width: 50px;
	text-align: center;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.app-search__icon--outline {
	border-radius: 50%;
	border: 1px solid currentColor;
	padding: 0.2em;
}
