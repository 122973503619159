.app-collections-participating-journal__heading {
	font-size: $participating-journals-heading--font-size;
}

.app-collections-participating-journal__item {
	display: flex;
	padding-bottom: spacing(24);
	border-bottom: 1px solid greyscale(80);
}

.app-collections-participating-journal__item:last-child {
	border-bottom: none;
	padding-bottom: spacing(0);
}

.app-collections-participating-journal__title {
	font-size: 1.25rem;

	a {
		color: #333;
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
	}
}

.app-collections-participating-journal__link {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	margin-bottom: spacing(8);

	@include media-query('lg') {
		align-items: center;
	}
}

.app-collections-participating-journal__image {
	flex: 0 0 $participating-journals-image--size;
	max-width: $participating-journals-image--size;
	box-shadow: 0 0 5px 2px greyscale(50, 0.2);
	border: 2px solid #fff;
	border-radius: 4px;
	max-height: 125px;
}

.app-collections-participating-journal__info {
	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 0 1 auto;
	padding-left: spacing(16);
}
