.c-recent-articles__circle {
	position: relative;
	width: $c-recent-articles--circle-diameter;
	height: $c-recent-articles--circle-diameter;
	border-radius: $c-recent-articles--circle-diameter * 0.5;
	background-color: $c-recent-articles--circle-colour;
	margin: 0 spacing(8) spacing(4) 0;
}

.c-recent-articles__icon {
	fill: $c-recent-articles--icon-colour;
	margin-right: spacing(8);
}

.c-recent-articles__container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: baseline;
	flex-wrap: wrap;
	padding-top: spacing(16);
	margin-bottom: spacing(24);
	border-top: 1px solid greyscale(80);
}
